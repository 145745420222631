import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { BaseSearchParams } from "api/axiosInterface";
import { PaginationInfo } from "./interfaces";
import PaginationButton from "./PaginationButton";

export interface PaginationProps {
  paginationProps: PaginationInfo;
  baseParam: BaseSearchParams;
  setBaseParam: React.Dispatch<React.SetStateAction<BaseSearchParams>>;
}

export default function Pagination(props: PaginationProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const {
    baseParam,
    setBaseParam,
    paginationProps: { currentPage, pageSize, totalCount, totalPages },
  } = props;
  const spread = 2;
  let lesspages: number[] = [];
  let morepages: number[] = [];
  for (var t = spread; t > 0; t--) {
    if (currentPage! - t > 0) lesspages.push(currentPage! - t);
    if (currentPage! + t < totalPages! + 1) morepages.push(currentPage! + t);
  }
  lesspages = lesspages.sort();
  morepages = morepages.sort();
  if (totalCount === 0) return null;
  return (
    <>
      <Box px={{ base: "4", md: "6" }} pb="5">
        <HStack spacing="3" justify="space-between">
          {!isMobile && (
            <Text color="accent" fontSize="sm">
              Showing {(currentPage! - 1) * pageSize! + 1} to{" "}
              {totalCount >= pageSize
                ? (currentPage! - 1) * pageSize! + pageSize!
                : totalCount}{" "}
              of {totalCount} Items
            </Text>
          )}
          <ButtonGroup
            spacing="3"
            justifyContent="space-between"
            width={{ base: "full", md: "auto" }}
            variant="secondary"
          >
            <PaginationButton
              ariaLabel="First Page"
              tooltip="First"
              info="First"
              disabled={currentPage === 1}
              onClick={() =>
                setBaseParam({ ...baseParam, pageNumber: currentPage - 1 })
              }
            />
            <PaginationButton
              ariaLabel="Previous Page"
              tooltip="Previous"
              info=">"
              disabled={currentPage === 1}
              onClick={() =>
                setBaseParam({ ...baseParam, pageNumber: currentPage - 1 })
              }
            />

            {lesspages.map((pageNumber) => {
              return (
                <PaginationButton
                  ariaLabel={`Page Number ${pageNumber}`}
                  tooltip={`Page ${pageNumber}`}
                  key={pageNumber}
                  info={pageNumber.toString()}
                  onClick={() =>
                    setBaseParam({ ...baseParam, pageNumber: pageNumber })
                  }
                />
              );
            })}
            <PaginationButton
              ariaLabel="currentPage"
              tooltip="Current Page"
              info={currentPage!.toString()}
              onClick={() => {}}
            />

            {morepages.map((pageNumber) => {
              return (
                <PaginationButton
                  ariaLabel={`Page Number ${pageNumber}`}
                  tooltip={`Page ${pageNumber}`}
                  key={pageNumber}
                  info={pageNumber.toString()}
                  onClick={() =>
                    setBaseParam({ ...baseParam, pageNumber: pageNumber })
                  }
                />
              );
            })}
            <PaginationButton
              ariaLabel="Next Page"
              tooltip="Next"
              info="<"
              disabled={currentPage === totalPages}
              onClick={() =>
                setBaseParam({ ...baseParam, pageNumber: currentPage + 1 })
              }
            />
            {totalPages !== 1 && (
              <PaginationButton
                ariaLabel="Last Page"
                tooltip="Last"
                info="Last"
                disabled={currentPage === totalPages}
                onClick={() =>
                  setBaseParam({ ...baseParam, pageNumber: totalPages })
                }
              />
            )}
          </ButtonGroup>
        </HStack>
      </Box>
    </>
  );
}
