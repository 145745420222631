import VehicleTypeStack from "./stacks/VehicleTypeStack";

export interface DeleteVehicleTypeProps {}
export default function DeleteVehicleType(props: DeleteVehicleTypeProps) {
  const {} = props;
  return (
    <>
      <VehicleTypeStack
        additionalInputProps={{ isReadOnly: true }}
        additionalTextAreaProps={{ isReadOnly: true }}
      />
    </>
  );
}
