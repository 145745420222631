import VehicleTypeStack from "components/Features/Vehicle/vehicle-type/CRUD/stacks/VehicleTypeStack";

export interface UpdateVehicleTypeProps {}
export default function UpdateVehicleType(props: UpdateVehicleTypeProps) {
  const {} = props;
  return (
    <>
      <VehicleTypeStack />
    </>
  );
}
