import { ReactElement } from "react";
import RegularButton from "shared/components/button/regular/RegularButton";

export interface CrudOptionProps {
  aria_label: string;
  toolTip: string;
  method: () => void;
  icon: ReactElement;
}
export default function CrudOption(props: CrudOptionProps) {
  const { aria_label, icon, method, toolTip } = props;
  return (
    <>
      <RegularButton
        tooltip={toolTip}
        icon={icon}
        content=""
        buttonProps={{
          onClick:method,
          color:"accent",
          "aria-label":aria_label
        }}
      />
      
    </>
  );
}
