import { requests, useGenericCalls } from "api/genericCalls";
import { CreateVehicleRepairTemplateCommand, SingleVehicleRepairTemplateVm, UpdateVehicleRepairTemplateVm, VehicleRepairTemplateVm } from "api/Interface";


export default function useVehicleRepairTemplateCalls(){

    const url = process.env.REACT_APP_API_VEHICLEREPAIRTEMPLATE;
    
    const vehicleRepairTemplateCalls = useGenericCalls<
    VehicleRepairTemplateVm,
    CreateVehicleRepairTemplateCommand,
    SingleVehicleRepairTemplateVm,
    UpdateVehicleRepairTemplateVm
    >(url!);
    vehicleRepairTemplateCalls.Select = () => {
        return requests.get(`${url}/Select`, new URLSearchParams());
    }
    return vehicleRepairTemplateCalls
}
