import { theme as proTheme } from "@chakra-ui/pro-theme";
import { extendTheme, theme as baseTheme } from "@chakra-ui/react";
import { Button } from "shared/theme/component-styles/buttonStyle";
import "@fontsource/inter/variable.css";
import { selectTheme } from "./component-styles/selectStyle";
import { numberInputTheme } from "./component-styles/numberInputStyle";

export const theme = extendTheme(
  proTheme, 
  {
    colors: { ...baseTheme.colors, brand: baseTheme.colors.pink },
    components:{Select: selectTheme, Button, NumberInput: numberInputTheme }
  },
);
