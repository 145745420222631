import { InputProps } from "@chakra-ui/react";
import FormHookDateInput from "shared/components/form/input/form-hook-components/FormHookDateInput";

export interface ExpenditureDatePaidProps {
  additionDateProps?: InputProps;
}
export default function ExpenditureDatePaid(props: ExpenditureDatePaidProps) {
  const { additionDateProps } = props;
  return (
    <>
      <FormHookDateInput
        label="Date in which the expenditure was paid"
        property="datePaid"
        additionalDateProps={additionDateProps}
        rules={{ required: "This date is required" }}
      />
    </>
  );
}
