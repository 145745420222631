import { Button, ButtonGroup } from "@chakra-ui/react";
import { CrudTypes } from "hooks/crud/interface";

export interface CrudActionsProps {
  cancelMethod: () => void;
  type: CrudTypes;
  processMethod: () => void;
  isLoading: boolean;
}
export default function CrudActions(props: CrudActionsProps) {
  const { cancelMethod, processMethod, type, isLoading } = props;
  let info = "";
  let loadingInfo = "";
  switch (type) {
    case CrudTypes.Create: {
      info = "Create";
      loadingInfo = "Creating";
      break;
    }
    case CrudTypes.Delete: {
      info = "Delete";
      loadingInfo = "CrDeletingeating";
      break;
    }
    case CrudTypes.Update: {
      info = "Update";
      loadingInfo = "Updating";
      break;
    }
    case CrudTypes.Proceed: {
      info = "Proceed";
      loadingInfo = "Processing Request";
      break;
    }
    default: {
      info = "Create";
      loadingInfo = "Creating";
      break;
    }
  }

  // const info =
  //   type === CrudTypes.Create
  //     ? "Create"
  //     : type === CrudTypes.Delete
  //     ? "Delete"
  //     : "Update";

  // const loadingInfo =
  //   type === CrudTypes.Create
  //     ? "Creating"
  //     : type === CrudTypes.Delete
  //     ? "Deleting"
  //     : "Updating";

  return (
    <>
      <ButtonGroup>
        {type !== CrudTypes.View && type && (
          <>
            <Button
              isLoading={isLoading}
              loadingText={"Processing"}
              onClick={processMethod}
              variant="primary"
            >
              {info}
            </Button>
          </>
        )}
        {type !== CrudTypes.View && type !== CrudTypes.Delete && (
          <>
            <Button onClick={cancelMethod}>
              {type === CrudTypes.Proceed ? "Cancel" : "Reset"}
            </Button>
          </>
        )}
      </ButtonGroup>
    </>
  );
}
