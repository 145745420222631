import {
  HStack,
  Avatar,
  Box,
  Text,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useLogin from "components/Authentication/hooks/useLogin";

export interface UserProfileProps {}
export default function UserProfile(props: UserProfileProps) {
  const {} = props;
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const { claims, isAuthenticated, updateClaims, userName } = useContext(
    AuthenticationContext
  );
  const { logOut } = useLogin();
  return (
    <>
      <HStack spacing="3" ps="2">
        <Menu>
          <MenuButton
            as={IconButton}
            icon={
              <Avatar
                bg={""}
                color={"accent"}
                name={
                  userName
                }
              />
            }
            ml={2}
          ></MenuButton>
          <MenuList
            bg={"bg-surface"}
            shadow="lg"
            py="4"
            color={"accent"}
            px="3"
          >
            <Text fontWeight="medium" mb="2">
              Welcome{" "}
              {userName}
            </Text>

            <MenuDivider />
            {/* <MenuItem bg={""} as={NavLink} to="/config" rounded="md">
              Configuration Settings
            </MenuItem> */}
            <MenuDivider />
            <MenuItem
              bg={""}              
              onClick={() => {
                logOut(updateClaims, navigate);
              }}
              rounded="md"
            >
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </>
  );
}
