import { TextareaProps } from "@chakra-ui/react";
import { FieldValues, RegisterOptions, useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericTextArea from "shared/components/form/input/GenericTextArea";

export interface FormHookTextAreaProps {
  additionalTextareaProps?: TextareaProps;
  property:string;
  label:string;
  rules?: RegisterOptions<FieldValues>

}
export default function FormHookTextArea(
  props: FormHookTextAreaProps
) {
  const { additionalTextareaProps, label,property,rules } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <GenericTextArea
            textAreaProps={{
              ...register(property, rules),
              ...additionalTextareaProps,
            }}
          />
        }
        label={label}
        placeHolder=""
        errorMessage={errors[property]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
