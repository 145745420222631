import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  Button,
  DrawerProps,
} from "@chakra-ui/react";
import { ReactElement } from "react";

export interface GenericDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  btnRef?: React.RefObject<any>;
  header: string;
  size?: "sm" | "md" | "lg" | "xl" | "xs" | "full";
  footerProps?: DrawerFooterProps;
  content: ReactElement;
  placement?: "bottom" | "end" | "left" | "right" | "start" | "top";
}
export interface DrawerFooterProps {}
export default function GenericDrawer(props: GenericDrawerProps) {
  const {
    btnRef,
    isOpen,
    onClose,
    header,
    size = "lg",
    footerProps,
    content,
    placement = "right",
  } = props;
  return (
    <>
      <Drawer
        size={size}
        isOpen={isOpen}
        placement={placement}
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{header}</DrawerHeader>

          <DrawerBody>{content}</DrawerBody>

          {footerProps && (
            <>
              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="blue">Save</Button>
              </DrawerFooter>
            </>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}
