import { numberInputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const custom = definePartsStyle({
  field: {
    border: "1px solid",
    borderColor: "inherit",
    background: "inherit",
    fontWeight: "inherit",
    _hover: {
      border: "1px solid",
      borderColor: "gray.600",
      background: "inherit",
      fontWeight: "inherit",
    },
    _focus:{
        borderColor:"accent"
    }
  },
  stepper: {
    color: "accent",
  },
});

export const numberInputTheme = defineMultiStyleConfig({
  variants: { custom },
});
