import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Divider,
  Text,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import GenericDivider from "shared/components/divider/GenericDivider";
import ModalLoader from "shared/components/loader/ModalLoader";

export interface GenericModalProps {
  isLoading: boolean;
  status: boolean;
  onClose: () => void;
  modalHeaderProps: ReactElement;
  modalBodyProps: ReactElement;
  modalFooterProps: ReactElement;
  size?:
    | "md"
    | "sm"
    | "lg"
    | "xl"
    | "2xl"
    | (string & {})
    | "xs"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
}
export default function GenericModal(props: GenericModalProps) {
  const {
    modalBodyProps,
    modalFooterProps,
    modalHeaderProps,
    onClose,
    status,
    isLoading,
    size = "xl",
  } = props;
  return (
    <>
      <Modal
        scrollBehavior="outside"
        closeOnOverlayClick={false}
        isOpen={status}
        onClose={onClose}
        size={size}
      >
        <ModalOverlay />
        <ModalContent bg={"bg-surface"}>
          <ModalHeader>
            <Text color={"accent"}>{modalHeaderProps}</Text>
            <ModalLoader isLoading={isLoading} />
          </ModalHeader>
          <ModalCloseButton color={"accent"} />
          <GenericDivider />
          <ModalBody mt={5}>{modalBodyProps}</ModalBody>
          <GenericDivider />
          <ModalFooter>{modalFooterProps}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
