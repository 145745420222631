import { HStack, Stack } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface FormStackProps {
  children: ReactElement;
  spacing?: number;
  type?: "horizontal" | "vertical";
}
export default function FormStack(props: FormStackProps) {
  const { children, spacing = 7, type = "vertical" } = props;
  if (type === "horizontal") {
    return (
      <>
        <HStack spacing={5}>{children}</HStack>
      </>
    );
  }
  return (
    <>
      <Stack spacing={spacing}>{children}</Stack>
    </>
  );
}
