import MainStack from "./stack/MainStack";

export interface ViewVehicleProps {}
export default function ViewVehicle(props: ViewVehicleProps) {
  const {} = props;
  return (
    <>
      <MainStack
        additionalInputProps={{ isReadOnly: true }}
        additionalSelectProps={{ isReadOnly: true }}
        additionalTextAreaProps={{ isReadOnly: true }}
      />
    </>
  );
}
