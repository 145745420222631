import {
  Stack,
  Heading,
  useBreakpointValue,
  HStack,
  Button,
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { IAgentGenericCalls } from "api/axiosInterface";
import { ReactElement, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FiPlus } from "react-icons/fi";
import PrimaryButton from "shared/components/button/regular/PrimaryButton";
import TableLoader from "shared/components/loader/TableLoader";
import CrudModal, {
  CrudModalProps,
} from "shared/components/modal/crud-modal/CrudModal";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CrudTable from "shared/components/crud-table/CrudTable";
import {
  AdditionalOption,
  CrudComponentsProps,
  CrudOptions,
  CrudTableDisplayProps,
} from "hooks/crud/interface";
import useCrudFunctions from "hooks/crud/useCrudFunctions";
import useTable, { UseTableProps } from "hooks/crud/useTable";

export interface UseCrudTableProps<List, Create, Read, Update> {
  headers: TableColumnHeaderProps[];
  useTableProps: UseTableProps<List>;
  displayProps: CrudTableDisplayProps;
  crudComponents: CrudComponentsProps;
  calls: IAgentGenericCalls<List, Create, Read, Update>;
  defaultValue: Create;
  genericCrudOps?: CrudOptions;
  additionalOptions?: AdditionalOption[];
  onDoubleClick?: (data: any) => void;
}
export interface CrudFuncOption {
  aria_label: string;
  toolTip: string;
  body: ReactElement;
  icon: ReactElement;
}

export default function useCrudTable<List, Create, Read, Update>(
  props: UseCrudTableProps<List, Create, Read, Update>
) {
  const {
    displayProps,
    useTableProps,
    headers,
    calls,
    crudComponents,
    defaultValue,
    genericCrudOps = {
      create: true,
      delete: true,
      read: true,
      update: true,
    },
    additionalOptions = [],
    onDoubleClick,
  } = props;

  const {
    baseParams,
    itemsResponse,
    setBaseParams,
    tableLoading,
    triggerRefresh,
  } = useTable<List>(useTableProps);
  const {
    crudModalProps,
    entity,
    formHook,
    createMethod,
    viewMethod,
    updateMethod,
    deleteMethod,
    genericMethod,
  } = useCrudFunctions<List, Create, Read, Update>({
    apiCalls: calls,
    displayProps,
    crudComponents,
    defaultValue,
    triggerRefresh,
  });
  additionalOptions.forEach((x) => {
    if (x.additionalOps?.triggerReset === true) {
      x.additionalOps.triggerMethod = triggerRefresh;
    }
  });
  const component = (
    <>
      {/* {tableLoading && ( */}
      <>
        <TableLoader isLoading={tableLoading} />
      </>
      {/* )} */}
      <FormProvider {...formHook}>
        <Stack spacing={{ base: "8", lg: "6" }} height="full">
          <Stack
            spacing="4"
            direction={{ base: "column", lg: "row" }}
            justify="space-between"
            align={{ base: "start", lg: "center" }}
          >
            <Stack spacing="1">
              <Heading
                color={"accent"}
                size={useBreakpointValue({ base: "xs", lg: "sm" })}
                fontWeight="medium"
              >
                {displayProps.tableName}
              </Heading>
              <Text color="muted">{displayProps.caption}</Text>
            </Stack>
            <HStack spacing="3">
              {genericCrudOps.create && (
                <>
                  <PrimaryButton
                    icon={<FiPlus />}
                    tooltip="Create"
                    content=""
                    buttonProps={{
                      onClick: createMethod,
                    }}
                  />
                  {/* <Button onClick={createMethod} variant="primary">
                    Create
                  </Button> */}
                </>
              )}
            </HStack>
          </Stack>
          <Box
            bg="bg-surface"
            boxShadow={{ base: "none", md: useColorModeValue("sm", "sm-dark") }}
            borderRadius={useBreakpointValue({ base: "none", md: "lg" })}
            //border={"1px"}
            borderColor={"accent"}
          >
            <CrudTable
              additionalOptions={additionalOptions}
              tableProps={{
                onDoubleClick,
                data: itemsResponse.items,
                tableHeaderProps: {
                  baseParam: baseParams,
                  headers,
                  optionsStatus: "show",
                  setBaseParam: setBaseParams,
                },

                viewMethod,
                updateMethod,
                deleteMethod,
              }}
              paginationProps={itemsResponse.pagination}
              crudOptions={genericCrudOps}
            />
          </Box>
        </Stack>
        <CrudModal {...crudModalProps} />
      </FormProvider>
    </>
  );
  return {
    component: () => component,
  };
}
