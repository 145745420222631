import { TextareaProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericTextArea from "shared/components/form/input/GenericTextArea";

export interface VehicleRepairTemplateDescriptionProps {
  additionalTextareaProps?: TextareaProps;
}
export default function VehicleRepairTemplateDescription(
  props: VehicleRepairTemplateDescriptionProps
) {
  const { additionalTextareaProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <GenericTextArea
            textAreaProps={{
              ...register("description", {
                required: `Description of Vehicle Repair Template is required`,
              }),
              ...additionalTextareaProps,
            }}
          />
        }
        label={`Vehicle Repair Template Description`}
        placeHolder=""
        errorMessage={errors["description"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
