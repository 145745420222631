import ReminderMainStack from "./stack/ReminderMainStack";

export interface UpdateReminderProps {}
export default function UpdateReminder(props: UpdateReminderProps) {
  const {} = props;
  return (
    <>
      <ReminderMainStack />
    </>
  );
}
