import { Tbody, Td, Tr } from "@chakra-ui/react";
import {
  AdditionalOption,
  CrudOptions as crudDeciderOptions,
} from "hooks/crud/interface";
import { Fragment } from "react";
import CrudOptions from "shared/components/crud-table/CrudOptions";
import { TableColumnHeaderProps } from "./interfaces";
import { TableHeaderProps } from "./TableHeader";

export interface TableBodyProps {
  tableHeaderProps: TableHeaderProps;
  items: any[];
  viewMethod: (id: string) => void;
  updateMethod: (id: string) => void;
  deleteMethod: (id: string) => void;
  crudOptions?: crudDeciderOptions;
  additionalOptions?: AdditionalOption[];
  onDoubleClick?: (data: any) => void;
}
export default function TableBody(props: TableBodyProps) {
  const {
    items,
    tableHeaderProps: { headers, optionsStatus },
    viewMethod,
    updateMethod,
    deleteMethod,
    crudOptions,
    additionalOptions,
    onDoubleClick,
  } = props;
  return (
    <>
      {items.length === 0 && (
        <Tbody>
          <Tr>
            <Td colSpan={2}>No Data is Present</Td>
          </Tr>
        </Tbody>
      )}

      {items.length !== 0 && (
        <Tbody >
          {items.map((item) => {
            //if(optionsElement) optionsElement.props.id = item.id;
            return (
              <Fragment key={item.id}>
                <Tr onDoubleClick={()=>onDoubleClick!(item)}>
                  {headers
                    .sort((a, b) => a.order - b.order)
                    .map((header) => {
                      return (
                        <Fragment key={header.order}>
                          {header.renderMethod === undefined && (
                            <>
                              {header.order === 1 && (
                                <Td fontWeight={"semibold"} scope="row">
                                  {item[header.name]}
                                </Td>
                              )}
                              {header.order !== 1 && (
                                <Td>
                                  <>{item[header.name]}</>
                                </Td>
                              )}
                            </>
                          )}
                          {header.renderMethod !== undefined && (
                            <Td>{header.renderMethod(item[header.name])}</Td>
                          )}
                        </Fragment>
                      );
                    })}
                  {optionsStatus === "show" && (
                    <Td>
                      <CrudOptions
                        id={item[Object.keys(item)[0]]}
                        viewMethod={viewMethod}
                        updateMethod={updateMethod}
                        deleteMethod={deleteMethod}
                        crudOptions={crudOptions}
                        additionalOptions={additionalOptions}
                      />
                    </Td>
                  )}
                </Tr>
              </Fragment>
            );
          })}
        </Tbody>
      )}
    </>
  );
}
