import { createStandaloneToast } from "@chakra-ui/react";
import { getClaims } from "components/Authentication/hooks/useLogin";
import Main from "components/Home/Main";
import AppContext from "contexts/AppContext";
import AuthenticationContext, { IClaim } from "contexts/AuthenticationContext";
import { useEffect, useState } from "react";

export default function App() {
  const { ToastContainer, toast } = createStandaloneToast();
  const nClaims = getClaims();
   const [claims, setClaims] = useState<IClaim[]>(
    nClaims
  // [
  // //   {
  // //     property: "name",
  // //     value: "Renaldo Davis",
  // //   },
  // //   {
  // //     property: "updatePassword",
  // //     value: "True",
  // //   },
  //  ]
  );
  const [currentEntityId, setCurrentEntityId] = useState<string>("");

  useEffect(() => {
    console.log(currentEntityId);
  }, [currentEntityId]);

  const isAuthenticated = claims.length > 0;
  const isUpdate =
    claims?.find((x) => x.property === "isUpdate")?.value === "true";
    const userName = claims?.find((x) => x.property === "displayName")?.value as string;
    const email = claims?.find((x) => x.property === "email")?.value as string;
  return (
    <>
      <AppContext.Provider
        value={{
          toast,
          isLoading: false,
          setIsLoading: () => {},
          currentEntityId,
          setCurrentEntityId,
        }}
      >
        <AuthenticationContext.Provider
          value={{
            isAuthenticated,
            claims,
            isUpdate: isUpdate,
            updateClaims: setClaims,
            userName,
            email
          }}
        >
          <Main />
          <ToastContainer />
        </AuthenticationContext.Provider>
      </AppContext.Provider>
    </>
  );
}
