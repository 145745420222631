import { InputProps, useBreakpointValue } from "@chakra-ui/react";
import FormStack from "shared/components/form/organization/FormStack";
import VehicleChassisNumber from "../html/VehicleChassisNumber";
import VehicleLicensePlate from "../html/VehicleLicensePlate";

export interface PlatesStackProps {
  additionalInputProps?: InputProps;
}
export default function PlatesStack(props: PlatesStackProps) {
  const { additionalInputProps } = props;
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  return (
    <>
      <FormStack type={isDesktop ? "horizontal" : "vertical"}>
        <>
          <VehicleLicensePlate additionalInputProps={additionalInputProps} />
          <VehicleChassisNumber additionalInputProps={additionalInputProps} />
        </>
      </FormStack>
    </>
  );
}
