import { HStack, Th, Text, Icon } from "@chakra-ui/react";
import { ReactElement } from "react";
import { ActiveHeaderProps } from "./interfaces";

export interface TableHeadProps {
  title: string;
  id: number;
  sortable?: boolean;
  activeHeader: ActiveHeaderProps;
  setActiveHeader: React.Dispatch<React.SetStateAction<ActiveHeaderProps>>;
  arrowUp: ReactElement;
  arrowDown: ReactElement;
  sortName: string;
}
export default function TableHead(props: TableHeadProps) {
  const {
    activeHeader,
    arrowDown,
    arrowUp,
    id,
    setActiveHeader,
    sortName,
    title,
    sortable = true,
  } = props;
  return (
    <>
      <Th scope="col" borderColor={"accent"}>
        <HStack
          onClick={() => {
            if (sortable) {
              if (activeHeader.activeHeaderId === id) {
                if (activeHeader.sortDirection === "ascending") {
                  setActiveHeader({
                    activeHeaderId: id,
                    sortDirection: "descending",
                    activeName: sortName,
                  });
                } else {
                  setActiveHeader({
                    activeHeaderId: 999,
                    activeName: undefined,
                    sortDirection: "ascending",
                  });
                }
              } else {
                setActiveHeader({
                  activeHeaderId: id,
                  sortDirection: "ascending",
                  activeName: sortName,
                });
              }
            }
          }}
          spacing="1"
        >
          <Text>{title}</Text>
          {activeHeader.activeHeaderId === id ? (
            <>
              {activeHeader.sortDirection === "ascending" ? arrowUp : arrowDown}
            </>
          ) : null}
          {/* <Icon as={IoMdArrowDown} color="muted" boxSize="4" /> */}
        </HStack>
      </Th>
    </>
  );
}
