import { requests, useGenericCalls } from "api/genericCalls";
import { CreateVehicleCommand, SingleVehicleVm, UpdateVehicleVm, VehicleVm } from "api/Interface";


export default function useVehicleCalls(){

    const url = process.env.REACT_APP_API_VEHICLE;
    
    const vehicleCalls = useGenericCalls<
    VehicleVm,
    CreateVehicleCommand,
    SingleVehicleVm,
    UpdateVehicleVm
    >(url!);
    vehicleCalls.Select = () => {
        return requests.get(`${url}/Select`, new URLSearchParams());
    }
    return vehicleCalls
}
