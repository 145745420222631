import { ButtonProps, useDisclosure, Text } from "@chakra-ui/react";
import useAgent from "api/agent";
import { ReminderTemplateVm } from "api/Interface";
import ReminderTemplates from "components/Features/Vehicle/reminder-template/ReminderTemplates";
import Vehicles from "components/Features/Vehicle/vehicle/Vehicles";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import PrimaryButton from "shared/components/button/regular/PrimaryButton";
import GenericDrawer from "shared/components/drawer/GenericDrawer";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericInput from "shared/components/form/input/GenericInput";
import FormStack from "shared/components/form/organization/FormStack";

export interface ReminderTemplateIdForReminderProps {
  vehicleId?: string;
  isDisabled?: boolean;
  setReminderTemplate: React.Dispatch<React.SetStateAction<ReminderTemplateVm>>;
  reminderTemplate: ReminderTemplateVm;
}
export default function ReminderTemplateIdForReminder(
  props: ReminderTemplateIdForReminderProps
) {
  const {
    vehicleId,
    setReminderTemplate,
    reminderTemplate,
    isDisabled = false,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const agent = useAgent();
  useEffect(() => {
    onClose();
  }, [reminderTemplate]);
  const {
    register,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    register("reminderTemplateId", {
      required: "Please provide the template for this reminder",
    });
  }, []);

  return (
    <>
      <GenericFormControl
        labelVariant="regular"
        errorMessage={errors.vehicleId?.message as string | undefined}
        label={
          <>
            <FormStack type="horizontal" spacing={25}>
              <>
                <Text>Reminder Template</Text>
                <PrimaryButton
                  buttonProps={{
                    mb: 5,
                    "aria-label": "Vehicle Search",
                    onClick: () => onOpen(),
                    isDisabled: isDisabled? true: vehicleId === "" || vehicleId === undefined? true:false
                  }}
                  icon={<FiSearch />}
                  content=""
                />
              </>
            </FormStack>
          </>
        }
        placeHolder=""
        input={
          <>
            <GenericInput
              inputProps={{
                value: reminderTemplate.name,
                isReadOnly: true,
              }}
            />
          </>
        }
      />
      <GenericDrawer
        content={
          <ReminderTemplates
            setReminderTemplate={setReminderTemplate}
            vehicleId={vehicleId}
          />
        }
        header="Vehicles"
        isOpen={isOpen}
        onClose={onClose}
        placement={"top"}
        size="full"
      />
    </>
  );
}
