import { ButtonProps, IconButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";
import GenericButton from "../GenericButton";

export interface PrimaryButtonProps {
  icon?: ReactElement;
  content: string;
  tooltip?: string;
  buttonProps: ButtonProps | IconButtonProps;
}
export default function PrimaryButton(props: PrimaryButtonProps) {
  const { content, icon, buttonProps, tooltip } = props;
  let newButtonProps: ButtonProps | IconButtonProps = {
    ...buttonProps,
    variant: "primary",
  };
  return (
    <>
      <GenericButton
        tooltip={tooltip}
        buttonProps={newButtonProps}
        content={content}
        icon={icon}
      />
    </>
  );
}
