import { InputProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericInput from "shared/components/form/input/GenericInput";

export interface VehicleChassisNumberProps {
  additionalInputProps?: InputProps;
}
export default function VehicleChassisNumber(props: VehicleChassisNumberProps) {
  const { additionalInputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return <>
  <GenericFormControl
        input={
          <GenericInput
          inputProps={{
            ...register("chassisNumber", {
                required: `Vehicle Chassis Number is required`,
              }),
              
              ...additionalInputProps
          }}            
          />
        }
        label={`Vehicle Chassis Number`}
        placeHolder=""
        errorMessage={errors["chassisNumber"]?.message as string | undefined}
        isRequired={true}
      />
  </>;
}
