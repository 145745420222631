import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Stack,
} from "@chakra-ui/react";
import { ParentNavData } from "../HorizontalMenu/data";
import PhoneItem from "./PhoneItem";

export interface NavAccordionProps {
  data: ParentNavData[];
  onClose: VoidFunction
}
export default function NavAccordion(props: NavAccordionProps) {
  const { data,onClose } = props;
  return (
    <>
      <Accordion allowMultiple as="ul" listStyleType="none">
        {data.map((group) => (
          <AccordionItem key={group.title} as="li">
            <AccordionButton py="3" px="0">
              <Box
                flex="1"
                textAlign="start"
                fontSize="lg"
                fontWeight="semibold"
              >
                {group.title}
              </Box>
              <AccordionIcon fontSize="3xl" />
            </AccordionButton>
            <AccordionPanel pb="3" px="0" pt="0">
              <Stack>
                {group.children.map((item, index) => (
                  <PhoneItem key={index} data={item} onClose ={onClose}/>
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
}
