import {
    CreateReminderTemplateCommand,
    SingleReminderTemplateVm,
    UpdateReminderTemplateVm,
    ReminderTemplateVm,
  } from "api/Interface";
  import useCrudTable from "hooks/crud/useCrudTable";
  import { TableColumnHeaderProps } from "shared/components/table/interfaces";
  import CreateReminderTemplate from "components/Features/Vehicle/reminder-template/CRUD/CreateReminderTemplate";
  import UpdateReminderTemplate from "components/Features/Vehicle/reminder-template/CRUD/UpdateReminderTemplate";
  import useAgent from "api/agent";
  import AppContext from "contexts/AppContext";
  import { useContext, useEffect, useState } from "react";
  import ViewReminderTemplate from "components/Features/Vehicle/reminder-template/CRUD/ViewReminderTemplate";
  import DeleteReminderTemplate from "components/Features/Vehicle/reminder-template/CRUD/DeleteReminderTemplate";
  
  export interface ReminderTemplatesProps {
    setReminderTemplate?: React.Dispatch<React.SetStateAction<ReminderTemplateVm>>;
    vehicleId?:string;
  }
  export default function ReminderTemplates(props: ReminderTemplatesProps) {
    const { currentEntityId } = useContext(AppContext);
    const {vehicleId,setReminderTemplate} = props;
    const agent = useAgent().reminderTemplateCalls;
    const headers: TableColumnHeaderProps[] = [
      {
        displayName: "Name",
        name: "name",
        order: 1,
      },
      {
        displayName:"Description",
        name:"description",
        order:2
      }
     
    ];
  
    const { component } = useCrudTable<
      ReminderTemplateVm,
      CreateReminderTemplateCommand,
      SingleReminderTemplateVm,
      UpdateReminderTemplateVm
    >({
      displayProps: {
        tableName: "Reminder Templates",
        caption: "Table showing all Reminder Templates within the system",
        cardTitle: "Reminder Template",
      },
      useTableProps: {
        Method: vehicleId == "" || vehicleId == undefined? agent.Items: 
        (params)=>agent.GetUnusedReminderTemplates(vehicleId, params),
      },
      crudComponents: {
        create: <CreateReminderTemplate />,
        view:<ViewReminderTemplate/>,
        update: <UpdateReminderTemplate />,
        delete: <DeleteReminderTemplate/>,
      },
      calls: agent,
      defaultValue: {description:"",name:""},
      headers,
      onDoubleClick: setReminderTemplate == undefined? ()=>{}: setReminderTemplate
    });
  
    const {} = props;
    return <>{component()}</>;
  }
  