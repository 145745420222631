import { TextareaProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericTextArea from "shared/components/form/input/GenericTextArea";

export interface VehicleTypeDescriptionProps {
  additionalTextareaProps?: TextareaProps;
}
export default function VehicleTypeDescription(
  props: VehicleTypeDescriptionProps
) {
  const { additionalTextareaProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <GenericFormControl
        input={
          <GenericTextArea
            textAreaProps={{
              ...register("description", {
                required: `Description of Vehicle Type is required`,
              }),
              ...additionalTextareaProps,
            }}
          />
        }
        label={`Vehicle Type Description`}
        placeHolder=""
        errorMessage={errors["description"]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
