
import { InputProps } from "@chakra-ui/react";
import FormHookDateInput from "shared/components/form/input/form-hook-components/FormHookDateInput";

export interface NextExpenditureDueDateProps {
  additionDateProps?: InputProps;
}
export default function NextExpenditureDueDate(
  props: NextExpenditureDueDateProps
) {
  const { additionDateProps } = props;
  return (
    <>
      <FormHookDateInput
        label="Date in which the expenditure is due"
        property="nextDueDate"
        additionalDateProps={additionDateProps}
        rules={{ required: "This date is required" }}
      />
    </>
  );
}
