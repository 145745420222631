import { requests, useGenericCalls } from "api/genericCalls";
import { CreateVehicleBluePrintCommand, SingleVehicleBluePrintVm, UpdateVehicleBluePrintVm, VehicleBluePrintVm } from "api/Interface";


export default function useVehicleBluePrintCalls(){

    const url = process.env.REACT_APP_API_VEHICLEBLUEPRINT;
    
    const vehicleBluePrintCalls = useGenericCalls<
    VehicleBluePrintVm,
    CreateVehicleBluePrintCommand,
    SingleVehicleBluePrintVm,
    UpdateVehicleBluePrintVm
    >(url!);
    vehicleBluePrintCalls.Select = () => {
        return requests.get(`${url}/Select`, new URLSearchParams());
    }
    return vehicleBluePrintCalls
}
