import VehicleBluePrintStack from "./stack/VehicleBluePrintStack";

export interface UpdateVehicleBluePrintProps {}
export default function UpdateVehicleBluePrint(
  props: UpdateVehicleBluePrintProps
) {
  const {} = props;
  return (
    <>
      <VehicleBluePrintStack />
    </>
  );
}
