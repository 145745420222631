import ReminderTemplateMainStack from "./stack/ReminderTemplateMainStack";

export interface CreateReminderTemplateProps {}
export default function CreateReminderTemplate(
  props: CreateReminderTemplateProps
) {
  const {} = props;
  return (
    <>
      <ReminderTemplateMainStack />
    </>
  );
}
