import VehicleTypeStack from "./stacks/VehicleTypeStack";

export interface CreateVehicleTypeProps {}
export default function CreateVehicleType(props: CreateVehicleTypeProps) {
  const {} = props;
  return (
    <>
      <VehicleTypeStack />
    </>
  );
}
