import { Table } from "@chakra-ui/react";
import { AdditionalOption, CrudOptions } from "hooks/crud/interface";
import TableBody from "./TableBody";
import TableHeader, { TableHeaderProps } from "./TableHeader";

export interface TableProps {
  tableHeaderProps: TableHeaderProps;
  data: any[];
  viewMethod: (id: string) => void;
  updateMethod: (id: string) => void;
  deleteMethod: (id: string) => void;
  crudOptions?: CrudOptions;
  additionalOptions?: AdditionalOption[];
  onDoubleClick?: (data: any) => void;
}
export default function GenericTable(props: TableProps) {
  const {
    data,
    tableHeaderProps,
    viewMethod,
    updateMethod,
    deleteMethod,
    crudOptions,
    additionalOptions,
    onDoubleClick,
  } = props;
  return (
    <>
      <Table size={"sm"}>
        <TableHeader {...tableHeaderProps} />
        <TableBody
          onDoubleClick={onDoubleClick}
          items={data}
          tableHeaderProps={tableHeaderProps}
          viewMethod={viewMethod}
          updateMethod={updateMethod}
          deleteMethod={deleteMethod}
          crudOptions={crudOptions}
          additionalOptions={additionalOptions}
        />
      </Table>
    </>
  );
}
