import { selectAnatomy } from '@chakra-ui/anatomy'
import { getColor, mode } from "@chakra-ui/theme-tools"
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys)

const brandPrimary = definePartsStyle({
    field: {
        border: "2px solid",
        borderColor: "inherit",
        bg: "inherit",
        _focus: {
          borderColor: "accent",

        },
        
        _readOnly: {
          boxShadow: "none !important",
          userSelect: "all",
        },        
      },
  icon: {
    color: "accent"
  },

})

export const selectTheme = defineMultiStyleConfig({
  variants: { brandPrimary },
})