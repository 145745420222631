import MainStack from "./stack/MainStack";

export interface CreateVehicleProps {}
export default function CreateVehicle(props: CreateVehicleProps) {
  const {} = props;
  return (
    <>
      <MainStack />
    </>
  );
}
