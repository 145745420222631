import VehicleTypeStack from "./stacks/VehicleTypeStack";

export interface ViewVehicleTypeProps {}
export default function ViewVehicleType(props: ViewVehicleTypeProps) {
  const {} = props;
  return (
    <>
      <VehicleTypeStack
        additionalInputProps={{ isReadOnly: true }}
        additionalTextAreaProps={{ isReadOnly: true }}
      />
    </>
  );
}
