import {
  CreateReminderCommand,
  SingleReminderVm,
  UpdateReminderVm,
  ReminderVm,
} from "api/Interface";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateReminder from "components/Features/Vehicle/reminder/CRUD/CreateReminder";
import UpdateReminder from "components/Features/Vehicle/reminder/CRUD/UpdateReminder";
import useAgent from "api/agent";
import ViewReminder from "components/Features/Vehicle/reminder/CRUD/ViewReminder";
import DeleteReminder from "components/Features/Vehicle/reminder/CRUD/DeleteReminder";

export interface RemindersProps {
  setReminder?: React.Dispatch<React.SetStateAction<ReminderVm>>;
}
export default function Reminders(props: RemindersProps) {
  const {setReminder} = props
  const agent = useAgent().reminderCalls;
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Name",
      name: "name",
      order: 1,
    },
    {
      displayName: "Vehcile",
      name: "vehicle",
      order: 2,
    },
    {
      displayName: "Due Date",
      name: "dateDue",
      order: 3,
    },
  ];

  const { component } = useCrudTable<
    ReminderVm,
    CreateReminderCommand,
    SingleReminderVm,
    UpdateReminderVm
  >({
    displayProps: {
      tableName: "Reminders",
      caption: "Table showing all reminder within the system",
      cardTitle: "Reminder",
    },
    useTableProps: {
      Method: agent.Items,
    },
    crudComponents: {
      create: <CreateReminder />,
      view: <ViewReminder />,
      update: <UpdateReminder />,
      delete: <DeleteReminder />,
    },
    genericCrudOps: {
      create: true,
      delete: false,
      read: true,
      update: true,
    },
    calls: agent,
    defaultValue: {
      vehicleId: "",
      reminderTemplateId: "",
      dueDate: new Date().toISOString().split("T")[0],
    },
    headers,
    onDoubleClick: setReminder? (item) => setReminder(item) : undefined,
  });

  const {} = props;
  return <>{component()}</>;
}
