import { SliderProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import NumberInput from "shared/components/form/number-input/NumberInput";
import GenericSlider from "shared/components/slider/GenericSlider";

export interface ExpenditureCostProps {
  sliderProps?: SliderProps;
}
export default function ExpenditureCost(props: ExpenditureCostProps) {
  const { sliderProps } = props;
  const formHook = useFormContext();
  return (
    <>
      <GenericFormControl
        errorMessage={
          formHook.formState.errors.minAge?.message as string | undefined
        }
        label={"Expenditure Cost"}
        isRequired={true}
        placeHolder={""}
        input={
          <>
            <NumberInput formHook={formHook} name="expenditureCost" props={{}}/>
          </>
        }
      />
    </>
  );
}
