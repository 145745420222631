import MainStack from "./stack/MainStack";

export interface UpdateVehicleProps {}
export default function UpdateVehicle(props: UpdateVehicleProps) {
  const {} = props;
  return (
    <>
      <MainStack />
    </>
  );
}
