import { Flex, HStack, StackDivider, IconButton, Icon } from "@chakra-ui/react";
import ColorSwitcher from "components/Authentication/ColorSwitcher";
import UserProfile from "components/Authentication/UserProfile";
import { RefObject } from "react";
import { FiMenu, FiSun } from "react-icons/fi";
import { MdClose } from "react-icons/md";

export interface PhoneMenuLayoutProps {
  onClickMenu?: VoidFunction;
  onToggleMode?: VoidFunction;
  isMenuOpen: boolean;
  menuButtonRef?: RefObject<HTMLButtonElement>;
}
export default function PhoneMenuLayout(props: PhoneMenuLayoutProps) {
  const { onClickMenu, onToggleMode, isMenuOpen, menuButtonRef } = props;
  const MenuIcon = isMenuOpen ? MdClose : FiMenu;
  return (
    <>
      <Flex height="16" align="center" justify="space-between" px="5">
        <HStack divider={<StackDivider height="6" alignSelf="unset" />}>
          <HStack>
            <UserProfile />
            <ColorSwitcher />
            {/* <Spacer /> */}
          </HStack>
          <IconButton
            ref={menuButtonRef}
            variant="ghost"
            icon={<Icon as={MenuIcon} fontSize="2xl" />}
            aria-label="Open Menu"
            onClick={onClickMenu}
          />
        </HStack>
      </Flex>
    </>
  );
}
