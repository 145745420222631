import { requests, useGenericCalls } from "api/genericCalls";
import {
  CreateReminderCommand,
  SingleReminderVm,
  UpdateReminderVm,
  ReminderVm,
  LoginCommand,
  UpdatePasswordCommand,
  SendUpdatePasswordEmailCommand,
} from "api/Interface";

export default function useAccountCalls() {
  const url = process.env.REACT_APP_API_ACCOUNT;

  const accountCalls = {
    Login: (data: LoginCommand) => {
      return requests.post(`${url}/login`, data);
    },
    UpdatePassword: (data: UpdatePasswordCommand) => {
      return requests.post(`${url}/updatePassword`, data);
    },
    ResetPasswordEmail: (data: SendUpdatePasswordEmailCommand) => {
      return requests.post(`${url}/resetPasswordEmail`, data);
    }
  };
  return accountCalls;
}
