import VehicleRepairTemplateStack from "./stacks/VehicleRepairTemplateStack";

export interface CreateVehicleRepairTemplateProps {}
export default function CreateVehicleRepairTemplate(props: CreateVehicleRepairTemplateProps) {
  const {} = props;
  return (
    <>
      <VehicleRepairTemplateStack />
    </>
  );
}
