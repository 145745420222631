import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  SelectProps,
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import {
  ChakraStylesConfig,
  Props,
  Select,
  SingleValue,
} from "chakra-react-select";
import { useEffect, useState } from "react";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";
import { HiArrowDownCircle } from "react-icons/hi2";
import useSelect from "./useSelect";

export interface CustomSelectProps {
  formHook: UseFormReturn<any, any>;
  placeHolder?: string;
  name: string;
  defOptions?: { value: string; label: string }[];
  fetchDataCall: (
    params: URLSearchParams
  ) => Promise<AxiosResponse<any, any>>;
  isEnum?: boolean;
  additionalProps?: Props;
}
export default function CustomSelect(props: CustomSelectProps) {
  const {
    additionalProps,
    fetchDataCall,
    formHook,
    name,
    defOptions = [],
    isEnum,
    placeHolder = "Select Item Below",
  } = props;
  const { options, setTitle, title } = useSelect({
    fetchDataCall,
    formHook,
    name,
    defOptions,
    isEnum,
    placeHolder,
  });
  const chakraStyles: ChakraStylesConfig<{ value: string; label: string }> = {
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "accent" : provided.background,
    }),
  };
  return (
    <>
      <Controller
        control={formHook.control}
        render={({ field: { onChange, value } }) => (
          <Select
            // value={value}
            // onChange={onChange}
            value={options.find((x) => x.value === value)}
            onChange={(newVal) => {
              const rr = newVal as SingleValue<{
                value: string;
                label: string;
              }>;
              setTitle({ value: rr!.value, label: rr!.label });
            }}
            options={[...options]}
           defaultValue={options.find((x) => x.value === value)}
            chakraStyles={chakraStyles}
            isReadOnly={additionalProps?.isReadOnly}
          />
        )}
        rules={{
          required: "Please provide an option",
          validate: (val) => {
            if (val.toString() === "0") return "Please provide an option";
          },
        }}
        name={name}
      />
    </>
  );
}

// <Select {...field} {...additionalInputProps}
// variant="brandPrimary"

// >
//   <option value={""}>Please choose here</option>
//   {options?.map((option, index) => (
//     <option
//     style={{
//       backgroundColor:"inherit"

//     }}
//     key={option.value} value={option.value}>
//       {option.label}
//     </option>
//   ))}
// </Select>

{
  /* <Controller
control={formHook.control}
render={({ field: {} }) => (
  <Menu matchWidth={true} closeOnSelect={true}>
    <MenuButton
      width={"80%"}
      as={Button}
      variant="primary"
      rightIcon={<HiArrowDownCircle />}
    >
      {title.label}
    </MenuButton>
    <MenuList minWidth="240px">
      <MenuDivider />
      <MenuOptionGroup title="Options">
        <MenuItemOption
          value="0"
          onClick={() => {
            setTitle({ label: placeHolder, value: "0" });
          }}
        >
          {placeHolder}
        </MenuItemOption>
        {options.map((option, index) => {
          return (
            <MenuItemOption
              key={index}
              value={option.value}
              onClick={() => {
                setTitle({ ...option });
              }}
            >
              {option.label}
            </MenuItemOption>
          );
        })}
      </MenuOptionGroup>
    </MenuList>
  </Menu>
)}
rules={{
  required: "Please provide an option",
}}
name={name}
/> */
}
