import axios from "axios";
import useVehicleTypeCalls from "api/Calls/Vehicle/vehicleTypeCalls";
import useVehicleBluePrintCalls from "api/Calls/Vehicle/vehicleBluePrintsCalls";
import useVehicleCalls from "api/Calls/Vehicle/vehicleCalls";
import useReminderTemplateCalls from "./Calls/Vehicle/reminderTemplateCalls";
import useReminderCalls from "./Calls/Vehicle/reminderCalls";
import useVehicleExpenditureCalls from "./Calls/Vehicle/vehicleExpenditureCalls";
import useVehicleRepairTemplateCalls from "./Calls/Vehicle/vehicleRepairTemplateCalls";
import useVehicleRepairExpenditureCalls from "./Calls/Vehicle/vehicleRepairExpenditureCalls";
import useAccountCalls from "./Calls/Account/accountCalls";

export default function useAgent() {
  const apiURL = process.env.REACT_APP_API_URL;
  axios.defaults.baseURL = apiURL;

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
     if (token) (config.headers! as any).Authorization = `Bearer ${token}`;
    config.headers!["Content-Type"] = "application/json";
    return config;
  });

  return {
    vehicleTypeCalls: useVehicleTypeCalls(),
    vehicleBluePrintCalls: useVehicleBluePrintCalls(),
    vehicleCalls: useVehicleCalls(),
    reminderTemplateCalls: useReminderTemplateCalls(),
    reminderCalls:useReminderCalls(),
    vehicleExpenditureCalls: useVehicleExpenditureCalls(),
    vehicleRepairTemplateCalls: useVehicleRepairTemplateCalls(),
    vehicleRepairExpenditureCalls: useVehicleRepairExpenditureCalls(),
    accountCalls: useAccountCalls()
  };
}
