import { As, Button, ButtonProps, IconButton, IconButtonProps, Tooltip } from "@chakra-ui/react";
import React from "react";
import { ReactElement } from "react";

export interface GenericButtonProps {
  icon?: ReactElement;
  content: any;  
  tooltip?: string;
  buttonProps:ButtonProps|IconButtonProps
}

const GenericButton = React.forwardRef<any, GenericButtonProps>(
  (props: GenericButtonProps, ref) => {
    const {
      buttonProps,
      content,
      icon,     
      tooltip = "",
    } = props;
    let yy = <></>;
    if (icon) {
      yy = (
        <IconButton 
          ref={ref}
          position="relative"
          icon={icon}
         {...buttonProps as IconButtonProps}
        >
          {content}
        </IconButton>
      );
    } else {
      yy = (
        <Button
          ref={ref}
          {...buttonProps as ButtonProps}          
          position="relative"         
        >
          {content}
        </Button>
      );
    }
    if (!tooltip) return <>{yy}</>;
    return (
      <>
        <Tooltip label={tooltip} openDelay={300}>
          {yy}
        </Tooltip>
      </>
    );
  }
);
export default GenericButton;
