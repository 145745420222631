import RegularButton from "shared/components/button/regular/RegularButton";

export interface PaginationButtonProps {
  info: string;
  disabled?: boolean;
  onClick: () => void;
  tooltip: string;
  ariaLabel: string;
}
export default function PaginationButton(props: PaginationButtonProps) {
  const { disabled = false, info, onClick, tooltip, ariaLabel } = props;
  return (
    <>
      <RegularButton
        tooltip={tooltip}
        content={info}
        buttonProps={{
          onClick,
          color: "accent",
          "aria-label": ariaLabel,
          isDisabled:disabled
        }}
      />
    </>
  );
}
