import { CreateToastFnReturn } from "@chakra-ui/react";
import { createContext } from "react";

export interface AppContextProps {
  isLoading: boolean;
  setIsLoading: () => void;
  toast?: CreateToastFnReturn;
  currentEntityId:string;
  setCurrentEntityId:(id:string)=>void;
}

const AppContext = createContext<AppContextProps>({
  isLoading: true,
  setIsLoading: () => {},
  currentEntityId:"",
  setCurrentEntityId:()=>{}
});

export default AppContext;