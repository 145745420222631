import ForgetPassword from "components/Authentication/ForgetPassword";
import Login from "components/Authentication/Login";
import UpdatePassword from "components/Authentication/UpdatePassword";
import VehicleExpenditures from "components/Features/Vehicle/reminder-expenditure/VehicleExpenditures";
import ReminderTemplates from "components/Features/Vehicle/reminder-template/ReminderTemplates";
import Reminders from "components/Features/Vehicle/reminder/Reminders";
import VehicleBluePrints from "components/Features/Vehicle/vehicle-blueprint/VehicleBluePrints";
import VehicleRepairExpenditures from "components/Features/Vehicle/vehicle-repairExpenditure/VehicleRepairExpenditures";
import VehicleRepairTemplates from "components/Features/Vehicle/vehicle-repairTemplate/VehicleRepairTemplates";
import VehicleTypes from "components/Features/Vehicle/vehicle-type/VehicleTypes";
import Vehicles from "components/Features/Vehicle/vehicle/Vehicles";

export interface IRoute {
  path: string;
  component: JSX.Element;
}

const vehicleRoutes: IRoute[] = [
  {
    path: "/vehicle-types",
    component: <VehicleTypes />,
  },
  {
    path: "/vehicle-blueprints",
    component: <VehicleBluePrints />,
  },
  {
    path: "/vehicles",
    component: <Vehicles/>,
  },
  {
    path:"/reminder-templates",
    component:<ReminderTemplates/>
  },
  {
    path:"/reminders",
    component:<Reminders/>
  },
  {
    path:"/vehicle-expenditures",
    component:<VehicleExpenditures/>
  },
  {
    path:"/vehicle-repair-templates",
    component:<VehicleRepairTemplates/>
  },
  {
    path:"/vehicle-repair-expenditures",
    component:<VehicleRepairExpenditures/>
  },
  {
    path:"/login",
    component:<Login/>
  },
  {
    path:"/forget-password",
    component:<ForgetPassword/>
  },
  {
    path:"/update-password",
    component:<UpdatePassword/>
  }
];

const routes = {
  vehicleRoutes,
};
export default routes;
