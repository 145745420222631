import { InputProps, TextareaProps } from "@chakra-ui/react";
import useAgent from "api/agent";
import { Props } from "chakra-react-select";
import FormStack from "shared/components/form/organization/FormStack";
import VehicleBluePrintName from "../html/VehicleBluePrintName";
import VehicleBluePrintType from "../html/VehicleBluePrintType";

export interface VehicleBluePrintStackProps {
  additionalInputProps?: InputProps;
  additionalProps?: Props;
}
export default function VehicleBluePrintStack(
  props: VehicleBluePrintStackProps
) {
  const { additionalInputProps,additionalProps } = props;
  const agent = useAgent();
  return (
    <>
      <FormStack>
        <>
          <VehicleBluePrintName additionalInputProps={additionalInputProps} />
          <VehicleBluePrintType
            fetchDataCall={agent.vehicleTypeCalls.Select!}
            isEnum={false}
            title="Vehicle Type"
            property="vehicleTypeId"
            additionalProps={{ ...additionalProps }}
          />
        </>
      </FormStack>
    </>
  );
}
