import {
  FiType,
  FiTruck,
  FiAperture,
  FiTerminal,
  FiTrello,
  FiBattery,
} from "react-icons/fi";
import { BiMoney, } from "react-icons/bi";
import {GoMortarBoard} from "react-icons/go"

export interface ParentNavData {
  title: string;
  children: NavData[];
}


export interface NavData{
  title: string;
  description: string;
  href: string;
  icon: any;
}


const vehicleItems:ParentNavData = {
  title: "Vehicle"
  ,
  children: [
    {
      title: "Vehicle Types",
      description: "Create or edit existing Vehicle Types",
      href: "/vehicle-types",
      icon: FiType,
    },
    {
      title: "Vehicle Blue Prints",
      description:
        "Create or edit existing Vehicle Blue Prints. These are also Car Models, like 2012 Toyota Corolla, etc.",
      href: "/vehicle-blueprints",
      icon: FiAperture,
    },
    {
      title: "Vehicles",
      description:
        "Create or edit existing Vehicles. These are These are instances of a particular Vehicle BluePrint.",
      href: "/vehicles",
      icon: FiTruck,
    },
    {
      title: "Reminder Templates",
      description:
        "Create or edit existing Reminder Templates. These are the templates that you will use to create the actual reminders for each vehicle.",
      href: "/reminder-templates",
      icon: FiTerminal,
    },
    {
      title: "Reminders",
      description: "Create or edit existing Reminders.",
      href: "/reminders",
      icon: FiTrello,
    },
    {
      title: "Expenditures",
      description: "Create Vehicle Expenditures.",
      href: "/vehicle-expenditures",
      icon: BiMoney,
    },
    {
      title: "Vehicle Repair Templates",
      description: "Create or edit existing Vehicle Parts that can be repaired on a vehicle, outsidde of regular servicing.",
      href: "/vehicle-repair-templates",
      icon: FiBattery,
    },
    {
      title: "Vehicle Repair Expenditures",
      description: "Create or edit existing Vehicle Repair Expenditures.",
      href: "/vehicle-repair-expenditures",
      icon: GoMortarBoard,
    },
  ]
  
}
export const items: ParentNavData[] = [vehicleItems];
