import {
  CreateVehicleExpenditureCommand,
  // SingleVehicleExpenditureVm,
  // UpdateVehicleExpenditureVm,
  VehicleExpenditureVm,
} from "api/Interface";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateVehicleExpenditure from "components/Features/Vehicle/reminder-expenditure/CRUD/CreateVehicleExpenditure";
import UpdateVehicleExpenditure from "components/Features/Vehicle/reminder-expenditure/CRUD/UpdateVehicleExpenditure";
import useAgent from "api/agent";
import AppContext from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import ViewVehicleExpenditure from "components/Features/Vehicle/reminder-expenditure/CRUD/ViewVehicleExpenditure";

export interface VehicleExpendituresProps {}
export default function VehicleExpenditures(props: VehicleExpendituresProps) {
  const agent = useAgent().vehicleExpenditureCalls;
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Reminder",
      name: "reminder",
      order: 1,
    },
    {
      displayName: "Vehcile",
      name: "vehicle",
      order: 2,
    },
    // {
    //   displayName: "Amount",
    //   name: "expenditure",
    //   order: 3,
    // },
    {
      displayName: "Date Paid",
      name: "datePaid",
      order: 3,
    },
  ];

  const { component } = useCrudTable<
    VehicleExpenditureVm,
    CreateVehicleExpenditureCommand, null,null
    // SingleVehicleExpenditureVm,
    // UpdateVehicleExpenditureVm
  >({
    displayProps: {
      tableName: "Vehicle Expenditures",
      caption: "Table showing all vehicle expenditures within the system",
      cardTitle: "Vehicle Expenditure",
    },
    useTableProps: {
      Method: agent.Items,
    },
    crudComponents: {
      create: <CreateVehicleExpenditure />,
      view: <ViewVehicleExpenditure />,
      update: <UpdateVehicleExpenditure />,
      delete: <></>,
    },
    genericCrudOps: {
      create: true,
      delete: false,
      read: false,
      update: false,
    },
    calls: agent,
    defaultValue: {},
    headers,
  });

  const {} = props;
  return <>{component()}</>;
}
