import {
    CreateVehicleBluePrintCommand,
    SingleVehicleBluePrintVm,
    UpdateVehicleBluePrintVm,
    VehicleBluePrintVm,
  } from "api/Interface";
  import useCrudTable from "hooks/crud/useCrudTable";
  import { TableColumnHeaderProps } from "shared/components/table/interfaces";
  import CreateVehicleBluePrint from "components/Features/Vehicle/vehicle-blueprint/CRUD/CreateVehicleBluePrint";
  import UpdateVehicleBluePrint from "components/Features/Vehicle/vehicle-blueprint/CRUD/UpdateVehicleBluePrint";
  import useAgent from "api/agent";
  import AppContext from "contexts/AppContext";
  import { useContext, useEffect, useState } from "react";
  import ViewVehicleBluePrint from "components/Features/Vehicle/vehicle-blueprint/CRUD/ViewVehicleBluePrint";
  import DeleteVehicleBluePrint from "components/Features/Vehicle/vehicle-blueprint/CRUD/DeleteVehicleBluePrint";
  
  export interface VehicleBluePrintsProps {}
  export default function VehicleBluePrints(props: VehicleBluePrintsProps) {
    const { currentEntityId } = useContext(AppContext);
    
    const agent = useAgent().vehicleBluePrintCalls;
    const headers: TableColumnHeaderProps[] = [
      {
        displayName: "Name",
        name: "name",
        order: 1,
      },
     
    ];
  
    const { component } = useCrudTable<
      VehicleBluePrintVm,
      CreateVehicleBluePrintCommand,
      SingleVehicleBluePrintVm,
      UpdateVehicleBluePrintVm
    >({
      displayProps: {
        tableName: "Vehicle Blue Prints",
        caption: "Table showing all vehicle blueprints within the system",
        cardTitle: "Vehicle BluePrints",
      },
      useTableProps: {
        Method: agent.Items,
      },
      crudComponents: {
        create: <CreateVehicleBluePrint />,
        view:<ViewVehicleBluePrint/>,
        update: <UpdateVehicleBluePrint />,
        delete: <DeleteVehicleBluePrint/>,
      },
      calls: agent,
      defaultValue: {name:"",vehicleTypeId:""},
      headers,
    });
  
    const {} = props;
    return <>{component()}</>;
  }
  