import { InputProps, TextareaProps } from "@chakra-ui/react";
import FormStack from "shared/components/form/organization/FormStack";
import ReminderDescription from "../html/ReminderDescription";
import ReminderName from "../html/ReminderName";

export interface ReminderTemplateMainStackProps {
  additionalTextAreaProps?: TextareaProps;
  additionalInputProps?: InputProps;
}
export default function ReminderTemplateMainStack(
  props: ReminderTemplateMainStackProps
) {
  const { additionalTextAreaProps, additionalInputProps } = props;
  return (
    <>
      <FormStack>
        <>
          <ReminderName additionalInputProps={additionalInputProps} />
          <ReminderDescription
            additionalTextAreaProps={additionalTextAreaProps}
          />
        </>
      </FormStack>
    </>
  );
}
