import { InputProps } from "@chakra-ui/react";
import { ReminderVm } from "api/Interface";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import FormStack from "shared/components/form/organization/FormStack";
import ExpenditureCost from "../html/ExpenditureCost";
import ExpenditureDatePaid from "../html/ExpenditureDatePaid";
import ExpenditureReminder from "../html/ExpenditureReminder";
import NextExpenditureDueDate from "../html/NextExpenditureDueDate";

export interface VehicleExpenditureMainStackProps {
  additionaInputProps?: InputProps;
  isDisabled?: boolean;
}
export default function VehicleExpenditureMainStack(
  props: VehicleExpenditureMainStackProps
) {
  const { additionaInputProps, isDisabled = false } = props;
  const [reminder, setReminder] = useState<ReminderVm>({});
  const count = useRef(0);
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useFormContext();
  useEffect(() => {
    const reminderId: string = getValues("reminderId");
    if (reminderId !== "" || (reminderId !== undefined && count.current < 1)) {
      setReminder({
        reminderId,
        name: getValues("reminderName"),
        vehicle: getValues("vehicle"),
        dateDue: getValues("dateDue"),
      });
    }
  }, []);
  useEffect(() => {
    setValue("reminderId", reminder.reminderId);
    if (count.current > 1) {
      trigger("reminderId");
    }
    count.current++;
  }, [reminder]);
  return (
    <>
      <FormStack>
        <>
          <ExpenditureReminder
            reminder={reminder}
            setReminder={setReminder}
            isDisabled={isDisabled}
          />

          <ExpenditureDatePaid additionDateProps={additionaInputProps} />
          <NextExpenditureDueDate additionDateProps={additionaInputProps} />
          <ExpenditureCost />
        </>
      </FormStack>
    </>
  );
}
