import { IAgentGenericCalls, IGetList } from "api/axiosInterface";
import { requests, useGenericCalls } from "api/genericCalls";
import {
  CreateReminderTemplateCommand,
  SingleReminderTemplateVm,
  UpdateReminderTemplateVm,
  ReminderTemplateVm,
} from "api/Interface";
import { AxiosResponse } from "axios";

export default function useReminderTemplateCalls() {
  const url = process.env.REACT_APP_API_REMINDERTEMPLATE;

  const reminderTemplateCalls: IReminderTemplateCalls<
    ReminderTemplateVm,
    CreateReminderTemplateCommand,
    SingleReminderTemplateVm,
    UpdateReminderTemplateVm
  > = {
    ...useGenericCalls<
      ReminderTemplateVm,
      CreateReminderTemplateCommand,
      SingleReminderTemplateVm,
      UpdateReminderTemplateVm
    >(url!),
    GetUnusedReminderTemplates: (
      vehicleId: string,
      params: URLSearchParams
    ) => {
      return requests.get(
        `${url}/UnusedTemplates/${vehicleId}`,
        params
      );
    },
  };
  reminderTemplateCalls.Select = () => {
    return requests.get(`${url}/Select`, new URLSearchParams());
  };
  return reminderTemplateCalls;
}

export interface IReminderTemplateCalls<List, Create, Read, Update>
  extends IAgentGenericCalls<List, Create, Read, Update> {
  GetUnusedReminderTemplates: (
    vehicleId: string,
    params: URLSearchParams
  ) => Promise<AxiosResponse<IGetList<List>, any>>;
}
