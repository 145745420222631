import MainStack from "./stack/MainStack";

export interface DeleteVehicleProps {}
export default function DeleteVehicle(props: DeleteVehicleProps) {
  const {} = props;
  return (
    <>
      <MainStack
        additionalInputProps={{ isReadOnly: true }}
        additionalSelectProps={{ isReadOnly: true }}
        additionalTextAreaProps={{ isReadOnly: true }}
      />
    </>
  );
}
