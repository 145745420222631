import { Fade } from "@chakra-ui/react";
import GenericModal, { GenericModalProps } from "../generic/GenericModal";
import CrudActions, { CrudActionsProps } from "./CrudActions";

export interface CrudModalProps {
  modalProps: GenericModalProps;
  actionProps: CrudActionsProps;
}
export default function CrudModal(props: CrudModalProps) {
  const { modalProps, actionProps } = props;
  actionProps.isLoading = modalProps.isLoading
  modalProps.modalFooterProps = (
    <>
      <CrudActions {...actionProps} />
    </>
  );
  modalProps.size = "5xl";
  return (
    <>
      <GenericModal {...modalProps} />
    </>
  );
}
