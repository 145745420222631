import {
  CreateVehicleRepairTemplateCommand,
  SingleVehicleRepairTemplateVm,
  UpdateVehicleRepairTemplateVm,
  VehicleRepairTemplateVm,
} from "api/Interface";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateVehicleRepairTemplate from "components/Features/Vehicle/vehicle-repairTemplate/CRUD/CreateVehicleRepairTemplate";
import UpdateVehicleRepairTemplate from "components/Features/Vehicle/vehicle-repairTemplate/CRUD/UpdateVehicleRepairTemplate";
import useAgent from "api/agent";
import ViewVehicleRepairTemplate from "components/Features/Vehicle/vehicle-repairTemplate/CRUD/ViewVehicleRepairTemplate";
import DeleteVehicleRepairTemplate from "components/Features/Vehicle/vehicle-repairTemplate/CRUD/DeleteVehicleRepairTemplate";

export interface VehicleRepairTemplatesProps {}
export default function VehicleRepairTemplates(props: VehicleRepairTemplatesProps) {
 
  const agent = useAgent().vehicleRepairTemplateCalls;
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Name",
      name: "name",
      order: 1,
    },
   
  ];

  const { component } = useCrudTable<
    VehicleRepairTemplateVm,
    CreateVehicleRepairTemplateCommand,
    SingleVehicleRepairTemplateVm,
    UpdateVehicleRepairTemplateVm
  >({
    displayProps: {
      tableName: "Vehicle Repair Templates",
      caption: "Table showing all Vehicle Repair Templates within the system",
      cardTitle: "Vehicle Repair Templates",
    },
    useTableProps: {
      Method: agent.Items,
    },
    crudComponents: {
      create: <CreateVehicleRepairTemplate />,
      view:<ViewVehicleRepairTemplate/>,
      update: <UpdateVehicleRepairTemplate />,
      delete: <DeleteVehicleRepairTemplate/>,
    },
    
    calls: agent,
    defaultValue: {name:"",description:""},
    headers,
  });

  const {} = props;
  return <>{component()}</>;
}
