import VehicleRepairTemplateStack from "./stacks/VehicleRepairTemplateStack";

export interface ViewVehicleRepairTemplateProps {}
export default function ViewVehicleRepairTemplate(props: ViewVehicleRepairTemplateProps) {
  const {} = props;
  return (
    <>
      <VehicleRepairTemplateStack
        additionalInputProps={{ isReadOnly: true }}
        additionalTextAreaProps={{ isReadOnly: true }}
      />
    </>
  );
}
