import { InputProps } from "@chakra-ui/react";
import FormHookTextInput from "shared/components/form/input/form-hook-components/FormHookInput";

export interface ReminderNameProps {
  additionalInputProps?: InputProps;
}
export default function ReminderName(props: ReminderNameProps) {
  const { additionalInputProps } = props;
  return (
    <>
      <FormHookTextInput
        label="Reminder Template Name"
        property="name"
        additionalInputProps={additionalInputProps}
        rules={{
          required: "Please Provide name of Reminder Template",
        }}
      />
    </>
  );
}
