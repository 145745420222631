import { defineStyleConfig, StyleFunctionProps } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Button = defineStyleConfig({
    // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {   
    gameCategory: (props) => ({
      bg:mode("accent","accent")(props),
      boxShadow: "md",
    }),
    gameCell: (props) => ({
      bg: "transparent",
      border: "1px solid",
      borderColor: mode("accent", "accent")(props),
      _hover: {
        transform: "scale(1.04)",
        boxShadow: "md",
        bg: mode(`${"brand"}.200`, `${"brand"}.500`)(props),
      },
      _active: {
        bg: mode(`${"brand"}.300`, `${"brand"}.600`)(props),
      },
      color: mode("red", "red")(props),
    }),
    gameQuestionCompleteCell: (props) => ({
      bg: "transparent",
      border: "1px solid",
      borderColor: mode("accent", "accent")(props),
      _hover: {
        transform: "scale(1.04)",
        boxShadow: "md",
        bg: mode(`${"brand"}.200`, `${"brand"}.500`)(props),
        color: mode(`${"brand"}.400`, `${"brand"}.100`)(props),
      },
      _active: {
        bg: mode(`${"brand"}.300`, `${"brand"}.600`)(props),
      },
      color: mode("accent", "accent")(props),
    }),
  },
  // The default `size` or `variant` values
  defaultProps: {},
})

