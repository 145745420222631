import {
  Button,
  Icon,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { IconType } from "react-icons/lib";
import { NavLink } from "react-router-dom";
import { PopoverIcon } from "./PopoverIcon";

export interface ResourcesPopoverProps {
  items: MenuItemProps[];
  header: string;
}

export default function ResourcesPopover(props: ResourcesPopoverProps) {
  const { header, items } = props;
  const {
    claims,
    isAuthenticated,    
  } = useContext(AuthenticationContext);
  
  return (
    <Popover
      trigger="hover"
      openDelay={200}
      placement="bottom"
      defaultIsOpen={false}
      gutter={12}
    >
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Button variant="link" rightIcon={<PopoverIcon isOpen={isOpen} />}>
              {header}
            </Button>
          </PopoverTrigger>
          <PopoverContent p="5" width={{ base: "sm", md: "5xl" }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} columnGap="6" rowGap="2">
              {items.map((item, id) => (
                <Link as={NavLink} variant="menu" to={item.href} key={id}>
                  <Stack spacing="4" direction="row" p="3">
                    <Icon as={item.icon} boxSize="6" color="accent" />
                    <Stack spacing="1">
                      <Text fontWeight="medium">{item.title}</Text>
                      <Text fontSize="sm" color="muted">
                        {item.description}
                      </Text>
                    </Stack>
                  </Stack>
                </Link>
              ))}
            </SimpleGrid>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}

export interface MenuItemProps {
  title: string;
  description: string;
  href: string;
  icon: IconType;
}
