import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
} from "@chakra-ui/react";
import { ReactElement } from "react";

export interface GenericFormControlProps {
  label: string | ReactElement;
  input: ReactElement;
  errorMessage?: string;
  placeHolder?: string;
  isRequired?: boolean;
  labelVariant?: "floating" | "regular";
}
export default function GenericFormControl(props: GenericFormControlProps) {
  const {
    input,
    label,
    placeHolder = "",
    errorMessage,
    isRequired,
    labelVariant = "floating",
  } = props;
  const isError = errorMessage !== undefined && errorMessage !== "";
  return (
    <>
      <FormControl isInvalid={isError} isRequired={isRequired}>
        {labelVariant === "regular" && (
          <>
            <FormLabel variant={labelVariant} size="lg">
              {label}
            </FormLabel>
            {input}
          </>
        )}
        {labelVariant !== "regular" && (
          <>
            {input}
            <FormLabel variant={labelVariant} size="lg">
              {label}
            </FormLabel>
          </>
        )}

        {!isError ? (
          <FormHelperText>{placeHolder}</FormHelperText>
        ) : (
          <>
            <FormErrorMessage>
              <FormErrorIcon></FormErrorIcon>
              {errorMessage}
            </FormErrorMessage>
          </>
        )}
      </FormControl>
    </>
  );
}
