import ReminderMainStack from "./stack/ReminderMainStack";

export interface CreateReminderProps {}
export default function CreateReminder(props: CreateReminderProps) {
  const {} = props;
  return (
    <>
      <ReminderMainStack />
    </>
  );
}
