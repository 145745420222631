import { requests, useGenericCalls } from "api/genericCalls";
import { CreateReminderCommand, SingleReminderVm, UpdateReminderVm, ReminderVm } from "api/Interface";


export default function useReminderCalls(){

    const url = process.env.REACT_APP_API_REMINDER;
    
    const reminderCalls = useGenericCalls<
    ReminderVm,
    CreateReminderCommand,
    SingleReminderVm,
    UpdateReminderVm
    >(url!);
    reminderCalls.Select = () => {
        return requests.get(`${url}/Select`, new URLSearchParams());
    }
    return reminderCalls
}
