import {
  useBreakpointValue,
  Container,
  HStack,
  ButtonGroup,
  Button,
  IconButton,
  Box,
} from "@chakra-ui/react";
import ColorSwitcher from "components/Authentication/ColorSwitcher";
import UserProfile from "components/Authentication/UserProfile";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { FiMenu } from "react-icons/fi";
import { items } from "./data";
import ResourcesPopover from "./ResourcesPopover";
export interface HorizontalMenuProps {}
export default function HorizontalMenu(props: HorizontalMenuProps) {
  const {} = props;

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Box as="section">
      <Box as="nav" bg="bg-surface" boxShadow="sm">
        <Container py={{ base: "4", lg: "5" }}>
          <HStack spacing="10" justify="space-between">
            {isDesktop ? (
              <>
                {items.map((item) => {
                  return (
                    <ButtonGroup variant="link" spacing="8" key={item.title}>
                      <ResourcesPopover header="Vehicle" items={item.children} />
                    </ButtonGroup>
                  );
                })}

                <HStack>
                  <ColorSwitcher />
                  <UserProfile />
                  {/* <Spacer /> */}
                </HStack>
              </>
            ) : (
              <IconButton
                variant="ghost"
                icon={<FiMenu fontSize="1.25rem" />}
                aria-label="Open Menu"
              />
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  );
}
