import { InputProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericInput from "shared/components/form/input/GenericInput";

export interface VehicleRepairTemplateNameProps {
  additionalInputProps?: InputProps;
}
export default function VehicleRepairTemplateName(props: VehicleRepairTemplateNameProps) {
  const { additionalInputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return <>
  <GenericFormControl
        input={
          <GenericInput
          inputProps={{
            ...register("name", {
                required: `Vehicle Repair Template Name is required`,
              }),
              
              ...additionalInputProps
          }}            
          />
        }
        label={`Vehicle Repair Template Name`}
        placeHolder=""
        errorMessage={errors["name"]?.message as string | undefined}
        isRequired={true}
      />
  </>;
}
