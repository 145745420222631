import { Input, InputProps } from "@chakra-ui/react";

export interface GenericInputProps {inputProps:InputProps}
export default function GenericInput(props: GenericInputProps) {
  const {inputProps} = props;
  return (
    <>
      <Input 
        size="lg"
        placeholder=" "
        data-peer       
        {...inputProps}
      />
    </>
  );
}
