import { InputProps, TextareaProps } from "@chakra-ui/react";
import FormStack from "shared/components/form/organization/FormStack";
import VehicleTypeDescription from "../html/VehicleTypeDescription";
import VehicleTypeName from "../html/VehicleTypeName";

export interface VehicleTypeStackProps {
    additionalInputProps?: InputProps;
    additionalTextAreaProps?: TextareaProps;
  }
  export default function VehicleTypeStack(props: VehicleTypeStackProps) {
    const { additionalInputProps, additionalTextAreaProps } = props;
    return (
      <>
        <FormStack>
          <>
            <VehicleTypeName additionalInputProps={additionalInputProps} />
            <VehicleTypeDescription additionalTextareaProps={additionalTextAreaProps} />
          </>
        </FormStack>
      </>
    );
  }
  