import VehicleBluePrintStack from "./stack/VehicleBluePrintStack";


export interface ComponentNameProps {}
export default function ComponentName(props: ComponentNameProps) {
  const {} = props;
  return (
    <>
      <VehicleBluePrintStack />
    </>
  );
}
