import { Textarea, TextareaProps } from "@chakra-ui/react";

export interface GenericTextAreaProps {
  textAreaProps: TextareaProps;
}
export default function GenericTextArea(props: GenericTextAreaProps) {
  const { textAreaProps } = props;
  return (
    <>
      <Textarea
      bg={"bg-surface"}
        size="lg"
        placeholder=" "
        data-peer
        {...textAreaProps}
        rows={5}
      />
    </>
  );
}
