import VehicleExpenditureMainStack from "./stack/VehicleExpenditureMainStack";

export interface CreateVehicleExpenditureProps {}
export default function CreateVehicleExpenditure(
  props: CreateVehicleExpenditureProps
) {
  const {} = props;
  return (
    <>
      <VehicleExpenditureMainStack />
    </>
  );
}
