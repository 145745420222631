import { Fade, Modal, ModalOverlay, Progress } from "@chakra-ui/react";

export interface TableLoaderProps {
  isLoading: boolean;
}
export default function TableLoader(props: TableLoaderProps) {
  const { isLoading } = props;
  return (
    <>
      <Fade in={isLoading}>
        <Progress isIndeterminate />
      </Fade>
    </>
  );
}
