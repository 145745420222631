import ReminderMainStack from "./stack/ReminderMainStack";

export interface ViewReminderProps {}
export default function ViewReminder(props: ViewReminderProps) {
  const {} = props;
  return (
    <>
      <ReminderMainStack isDisabled = {true} additionaInputProps={{ isReadOnly: true }} />
    </>
  );
}
