import { requests, useGenericCalls } from "api/genericCalls";
import { CreateVehicleRepairExpenditureCommand, 
    // SingleVehicleRepairExpenditureVm, UpdateVehicleRepairExpenditureVm, 
    VehicleRepairExpenditureVm } from "api/Interface";


export default function useVehicleRepairExpenditureCalls(){

    const url = process.env.REACT_APP_API_VEHICLEREPAIREXPENDITURE;
    
    const reminderCalls = useGenericCalls<
    VehicleRepairExpenditureVm,
    CreateVehicleRepairExpenditureCommand, null,null
    // SingleVehicleRepairExpenditureVm,
    // UpdateVehicleRepairExpenditureVm
    >(url!);
    reminderCalls.Select = () => {
        return requests.get(`${url}/Select`, new URLSearchParams());
    }
    return reminderCalls
}
