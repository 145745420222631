import VehicleBluePrintStack from "./stack/VehicleBluePrintStack";

export interface ViewVehicleBluePrintProps {}
export default function ViewVehicleBluePrint(props: ViewVehicleBluePrintProps) {
  const {} = props;
  return (
    <>
      <VehicleBluePrintStack additionalInputProps={{ isReadOnly: true }} additionalProps={{isReadOnly:true}} />
    </>
  );
}
