import { InputProps } from "@chakra-ui/react";
import FormHookDateInput from "shared/components/form/input/form-hook-components/FormHookDateInput";

export interface ReminderDateProps {
  additionDateProps?: InputProps;
}
export default function ReminderDate(props: ReminderDateProps) {
  const { additionDateProps } = props;
  return (
    <>
      <FormHookDateInput
        label="Reminder Date"
        property="dueDate"
        additionalDateProps={additionDateProps}
        rules={{ required: "Reminder Date is required" }}
      />
    </>
  );
}
