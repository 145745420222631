import { TextareaProps } from "@chakra-ui/react";
import FormHookTextArea from "shared/components/form/input/form-hook-components/FormHookTextArea";

export interface ReminderDescriptionProps {
  additionalTextAreaProps?: TextareaProps;
}
export default function ReminderDescription(props: ReminderDescriptionProps) {
  const { additionalTextAreaProps } = props;
  return (
    <>
      <FormHookTextArea
        label="Description of Reminder Template"
        property="description"
        additionalTextareaProps={additionalTextAreaProps}
        rules={{
          required:
            "Please provide a description. This helps you to differentiate between the templates",
          minLength: {
            value: 10,
            message: "Description needs to be atleast 10 characters long",
          },
        }}
      />
    </>
  );
}
