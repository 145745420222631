import { AxiosResponse } from "axios";
import { Props } from "chakra-react-select";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import CustomSelect from "shared/components/select/CustomSelect";

export interface VehicleBluePrintProps {
  additionalProps?: Props;
  title: string;
  property: string;
  isEnum: boolean;
  fetchDataCall: (params: URLSearchParams) => Promise<AxiosResponse<any, any>>;
  placeHolder?: string;
}
export default function VehicleBluePrint(props: VehicleBluePrintProps) {
  const formHook = useFormContext();
  const {
    additionalProps,
    property,
    title,
    fetchDataCall,
    isEnum,
    placeHolder = "Click to select an item",
  } = props;
  return (
    <>
      <GenericFormControl

        input={
          <CustomSelect
            isEnum={isEnum}
            fetchDataCall={fetchDataCall}
            formHook={formHook}
            name={property}
            additionalProps={additionalProps}
            placeHolder={placeHolder}            
          />
        }
        label={title}
        placeHolder=""
        errorMessage={
          formHook.formState.errors[property]?.message as string | undefined
        }
        isRequired={true}
      />
    </>
  );
}
