import {
  Box,
  Button,
  Container,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import useAgent from "api/agent";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useToast } from "shared/components/toast/useToast";
import useLogin from "./hooks/useLogin";
import { Sleep } from "shared/functions/generic/asyncFunctions";
import GenericFormControl from "shared/components/form/GenericForm";

export interface UpdatePasswordProps {}
export default function UpdatePassword(props: UpdatePasswordProps) {
  const {} = props;
  const agent = useAgent().accountCalls;
  const [show, setShow] = useState(false);
  const { ProcessingToast, ErrorToast, toast, SuccessToast } = useToast();
  const { updateClaims, email } = useContext(AuthenticationContext);
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { logOut } = useLogin();
  async function onSubmit(data: any) {
    var proId = await ProcessingToast();
    try {
      await Sleep(350);
      await agent.UpdatePassword({ ...data, email });
      toast!.close(proId);
      SuccessToast({
        description: "Password has been updated",
        position: "top-right",
      }).then(() => {
        logOut(updateClaims, navigate);
      });
    } catch (error) {
      toast!.close(proId);
      ErrorToast({
        description: "Please check password",
        position: "top-right",
      });
    }
  }
  function onError() {}
  return (
    <>
      <Container
        maxW="lg"
        py={{ base: "12", md: "24" }}
        px={{ base: "0", sm: "8" }}
      >
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
              <Heading size={{ base: "xs", md: "sm" }}>
                Reset Your Password
              </Heading>
            </Stack>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg-surface" }}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Stack spacing="6">
              <Stack spacing="5">
                <GenericFormControl
                  input={
                    <>
                      <InputGroup>
                        <InputLeftElement children={<FaLock />} />
                        <Input
                          type={show ? "text" : "password"}
                          {...register("password", {
                            required: "Password is Required",
                          })}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </>
                  }
                  label="Password"
                  errorMessage={
                    errors["password"]?.message as string | undefined
                  }
                  isRequired={true}
                  placeHolder={""}
                />
                <GenericFormControl
                  input={
                    <>
                      <InputGroup>
                        <InputLeftElement children={<FaLock />} />
                        <Input
                          type={show ? "text" : "password"}
                          {...register("confirmPassword", {
                            required: "Password is Required",
                          })}
                        />
                        <InputRightElement width="4.5rem">
                          <Button
                            h="1.75rem"
                            size="sm"
                            onClick={() => setShow(!show)}
                          >
                            {show ? "Hide" : "Show"}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </>
                  }
                  label="Confirm Password"
                  errorMessage={
                    errors["confirmPassword"]?.message as string | undefined
                  }
                  isRequired={true}
                  placeHolder={""}
                />
              </Stack>

              <Stack spacing="6">
                <Button
                  onClick={handleSubmit(onSubmit, onError)}
                  variant="primary"
                >
                  Update Password
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
