import { ButtonProps, IconButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";
import GenericButton from "../GenericButton";

export interface RegularButtonProps {
  icon?: ReactElement;
  content: any;
  tooltip?: string;
  buttonProps: ButtonProps | IconButtonProps;
}
export default function RegularButton(props: RegularButtonProps) {
  const { content, icon, buttonProps,tooltip } = props;
  let newButtonProps: ButtonProps | IconButtonProps = {
    ...buttonProps,
    variant: "solid",
    color:"accent"
  };
  return (
    <>
        <GenericButton
         tooltip={tooltip}
        buttonProps={newButtonProps}
        content={content}
        icon={icon}
      />
    </>
  );
}
