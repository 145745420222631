import {
  CreateVehicleRepairExpenditureCommand,
  // SingleVehicleRepairExpenditureVm,
  // UpdateVehicleRepairExpenditureVm,
  VehicleRepairExpenditureVm,
} from "api/Interface";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateVehicleRepairExpenditure from "components/Features/Vehicle/vehicle-repairExpenditure/CRUD/CreateVehicleRepairExpenditure";
import UpdateVehicleRepairExpenditure from "components/Features/Vehicle/vehicle-repairExpenditure/CRUD/UpdateVehicleRepairExpenditure";
import useAgent from "api/agent";
import ViewVehicleRepairExpenditure from "components/Features/Vehicle/vehicle-repairExpenditure/CRUD/ViewVehicleRepairExpenditure";

export interface VehicleRepairExpendituresProps {}
export default function VehicleRepairExpenditures(props: VehicleRepairExpendituresProps) {
  const agent = useAgent().vehicleRepairExpenditureCalls;
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Repair Template",
      name: "template",
      order: 1,
    },
    {
      displayName: "Vehicle",
      name: "vehicle",
      order: 2,
    },
    {
      displayName: "License Plate",
      name: "licensePlate",
      order: 3,
    },
    {
      displayName: "Expenditure Date",
      name: "date",
      order:4,
    },
  ];

  const { component } = useCrudTable<
    VehicleRepairExpenditureVm,
    CreateVehicleRepairExpenditureCommand, null,null
    // SingleVehicleRepairExpenditureVm,
    // UpdateVehicleRepairExpenditureVm
  >({
    displayProps: {
      tableName: "Vehicle Repair Expenditures",
      caption: "Table showing all vehicle repair expenditures within the system",
      cardTitle: "Vehicle Repair Expenditure",
    },
    useTableProps: {
      Method: agent.Items,
    },
    crudComponents: {
      create: <CreateVehicleRepairExpenditure />,
      view: <ViewVehicleRepairExpenditure />,
      update: <UpdateVehicleRepairExpenditure />,
      delete: <></>,
    },
    genericCrudOps: {
      create: true,
      delete: false,
      read: false,
      update: true,
    },
    calls: agent,
    defaultValue: {},
    headers,
  });

  const {} = props;
  return <>{component()}</>;
}
