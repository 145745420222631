import { useBreakpointValue } from "@chakra-ui/react";
import {
  ReactElement,
  useContext,
  useEffect,
  useRef,
} from "react";
import { redirect, useNavigate } from "react-router-dom";
import AuthenticationContext from "../../contexts/AuthenticationContext";
import Login from "./Login";
import UpdatePassword from "./UpdatePassword";

export interface AuthorizeProps {
  element: ReactElement;  
  showMenu?: boolean;
}

export default function Authorize(props: AuthorizeProps) {
  const {
    element,
    showMenu = true,
  } = props;
  const trigger = useRef("");
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const {
    isAuthenticated, 
    isUpdate,
  } = useContext(AuthenticationContext);
  const navigate = useNavigate();
   console.log(isAuthenticated, isUpdate)
  useEffect(() => {
    if (trigger.current !== "") {
      navigate(trigger.current);
    }
  }, [trigger.current]);

  if (!isAuthenticated) {
    
    trigger.current = "/login";
    return <Login/>;
  }
  if (isUpdate) {
    trigger.current = "/update-password";
    return <UpdatePassword/>;
  }
  

return <>{element}</>;
}
