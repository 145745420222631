import { requests, useGenericCalls } from "api/genericCalls";
import { CreateVehicleExpenditureCommand, 
    // SingleVehicleExpenditureVm, UpdateVehicleExpenditureVm, 
    VehicleExpenditureVm } from "api/Interface";


export default function useVehicleExpenditureCalls(){

    const url = process.env.REACT_APP_API_VEHICLEEXPENDITURE;
    
    const reminderCalls = useGenericCalls<
    VehicleExpenditureVm,
    CreateVehicleExpenditureCommand, null,null
    // SingleVehicleExpenditureVm,
    // UpdateVehicleExpenditureVm
    >(url!);
    reminderCalls.Select = () => {
        return requests.get(`${url}/Select`, new URLSearchParams());
    }
    return reminderCalls
}
