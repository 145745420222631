import {
  useDisclosure,
  Text,
  InputGroup,
  InputLeftAddon,
  Input,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { ReminderVm } from "api/Interface";
import Reminders from "components/Features/Vehicle/reminder/Reminders";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import PrimaryButton from "shared/components/button/regular/PrimaryButton";
import GenericDrawer from "shared/components/drawer/GenericDrawer";
import GenericFormControl from "shared/components/form/GenericForm";
import FormStack from "shared/components/form/organization/FormStack";

export interface ExpenditureReminderProps {
  isDisabled?: boolean;
  setReminder: React.Dispatch<React.SetStateAction<ReminderVm>>;
  reminder: ReminderVm;
}
export default function ExpenditureReminder(props: ExpenditureReminderProps) {
  const { reminder, setReminder, isDisabled = false } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    formState: { errors },
  } = useFormContext();
  useEffect(() => {
    onClose();
  }, [reminder]);
  return (
    <>
      <GenericFormControl
        labelVariant="regular"
        errorMessage={errors.vehicleId?.message as string | undefined}
        label={
          <>
            <FormStack type="horizontal" spacing={25}>
              <>
                <Text>Reminder</Text>
                <PrimaryButton
                  buttonProps={{
                    mb: 5,
                    "aria-label": "Reminder Search",
                    onClick: () => onOpen(),
                    isDisabled: isDisabled,
                  }}
                  icon={<FiSearch />}
                  content=""
                />
              </>
            </FormStack>
          </>
        }
        placeHolder=""
        input={
          <>
            <HStack>
              <InputGroup size={"sm"}>
                <InputLeftAddon>Reminder:</InputLeftAddon>
                <Input type="text" value={reminder.name} isDisabled={true} />
              </InputGroup>

              <InputGroup size={"sm"}>
                <InputLeftAddon>Vehicle:</InputLeftAddon>
                <Input type="text" value={reminder.vehicle} isDisabled={true} />
              </InputGroup>
              <InputGroup size={"sm"}>
                <InputLeftAddon>Due Date:</InputLeftAddon>
                <Input type="text" value={reminder.dateDue} isDisabled={true} />
              </InputGroup>
            </HStack>
          </>
        }
      />
      <GenericDrawer
        content={<Reminders setReminder={setReminder} />}
        header="Vehicles"
        isOpen={isOpen}
        onClose={onClose}
        placement={"top"}
        size="full"
      />
    </>
  );
}
