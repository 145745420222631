import { Button, HStack, Icon, Link,Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { NavData } from "../HorizontalMenu/data";

export interface PhoneItemProps {
  data: NavData;
  onClose: VoidFunction
}
export default function PhoneItem(props: PhoneItemProps) {
  const {data:{ href, icon, title}, onClose } = props;
  return (
    <Button as={NavLink} to={href} variant={"unstyled"} onClick={onClose}>
      <HStack py="3" spacing="3">
        <Icon color="accent" as={icon} fontSize="xl" />
        <Text fontWeight="medium">{title}</Text>
      </HStack>
    </Button>
  );
}

