// import agent from "api/agent";
// import exp from "constants";
// import AuthenticationContext, { IClaim } from "contexts/AuthenticationContext";
// import { useContext } from "react";
// import { useForm } from "react-hook-form";
// import { NavigateFunction, useNavigate } from "react-router-dom";
// import { useToast } from "shared/components/toast/useToast";
// import { LoginDTO } from "../interfaces";

import { LoginCommand } from "api/Interface";
import useAgent from "api/agent";
import AuthenticationContext, { IClaim } from "contexts/AuthenticationContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useToast } from "shared/components/toast/useToast";

export interface IAuthenticationResponse {
  token: string;
  expiration: string;
}
const tokenKey = "token";
const expirationKey = "token-expiration";
export default function useLogin() {
  const agent = useAgent().accountCalls;
  const { updateClaims } = useContext(AuthenticationContext);
  const { ErrorToast, SuccessToast } = useToast();
  const navigate = useNavigate();
  const formHook = useForm<LoginCommand>();
  let submit: (data: LoginCommand) => void = async (data: LoginCommand) => {
    try {
      const response = await agent.Login(data);
      SuccessToast({
        description: "Welcome",
      });
      saveToken(response.data, updateClaims, navigate);
    } catch (error) {
      ErrorToast({
        description: "UserName or Password is Incorrect. Please try again.",
      });
    }
    // await agent.Login(data)
    //   .then((response) => {
    //     // toast.success("Success, you will be redirected.");
    //     SuccessToast({
    //       description: "Welcome",
    //     });
    //     saveToken(response.data.result.token, updateClaims,navigate);
    //   })
    //   .catch((error) => {
    //     ErrorToast({
    //       description: "UserName or Password is Incorrect. Please try again.",
    //     });
    //   });
  };
  let error: (error: any) => void = (error: any) => {};
  return {
    formHook,
    submit,
    error,
    logOut,
  };
}

function saveToken(
  response: IAuthenticationResponse,
  updateClaims: any,
  navigate: NavigateFunction
) {
  console.log(response.token);
  localStorage.setItem(tokenKey, response.token);
  // localStorage.setItem(expirationKey, response.expiration);
  const claims = getClaims();
  
  updateClaims(claims);
  const isUpdate = claims.find((x) => x.property === "isUpdate")!.value;
  if (isUpdate === "true") {
    navigate("/updatePassword");
  } else {
    navigate("/");
  }
}

export function getClaims(): IClaim[] {
  const token = localStorage.getItem(tokenKey);
  if (!token) {
    return [];
  }
  // const expiration = localStorage.getItem(expirationKey)!;
  // console.log(expiration);
  // if (new Date(expiration) <= new Date()) {
  //   localStorage.removeItem(tokenKey);
  //   localStorage.removeItem(expirationKey);
  //   return [];
  // }

  const dataToken = JSON.parse(atob(token.split(".")[1]));
  console.log(dataToken);
  const claims: IClaim[] = [];
  Object.keys(dataToken).forEach((key) => {
    if (key === "roles") {
      let roles = JSON.parse(dataToken[key]) as string[];
      claims.push({ property: key, value: roles });
    } else {
      claims.push({ property: key, value: dataToken[key] });
    }
  });
  const exp = dataToken.expp;
  console.log(new Date(exp), new Date())
  if (new Date(exp) <= new Date()) {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(expirationKey);
    return [];
  }
  return claims;
}

export function logOut(updateClaims: any, navigate: NavigateFunction) {
  localStorage.removeItem(tokenKey);
  const claims = getClaims();
  navigate("/login");
  updateClaims(claims);
}
