import { CallResponse, ServerError, ServerErrorResult } from "api/axiosInterface";
import { AxiosResponse } from "axios";
import { ReactElement } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { AdditionOps } from "shared/components/crud-table/AuxillaryCrudOption";
import { CrudActionsProps } from "shared/components/modal/crud-modal/CrudActions";
import { CrudModalProps } from "shared/components/modal/crud-modal/CrudModal";

export enum CrudTypes {
  Create = 1,
  Update = 2,
  Delete = 3,
  View = 4,
  Proceed = 5  
}

export interface CrudTableDisplayProps {
  tableName: string;
  caption: string;
  cardTitle: string;
}
export interface CrudComponentsProps {
  create: ReactElement;
  update: ReactElement;
  view: ReactElement;
  delete: ReactElement;
}

export interface CrudFuctionState {
  method: (data: any) => Promise<AxiosResponse<any, any>>;
  entity: any;
  crudModalProps: CrudModalProps;
}
export interface CrudFunctionAction {
  type:
    | "processMethod"
    | "closeModal"
    | "resetForm"
    | "setModalLoading"
    | "serverErrors"
    | "submit";
  data:
    | CreateMethod
    | undefined
    | UseFormReturn<FieldValues, any>
    | boolean
    | ServerErrorProps
    | any;
}

export interface CreateMethod {
  entity: any;
  method: (data: any, id?:string) => Promise<AxiosResponse<any, any>>;
  crudActionProps: CrudActionsProps;
  body: ReactElement;
  modalHeaderProps: ReactElement;
  formHook: UseFormReturn<FieldValues, any>;
}

export interface ServerErrorProps {
  formHook: UseFormReturn;
  errors: ServerError[];
}

export interface CrudOptions {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
}

export interface AdditionalOption {
  aria_label: string;
  toolTip: string;
  method: (id: string) => void;
  icon: ReactElement;
  additionalOps?:AdditionOps
}

