import ReminderTemplateMainStack from "./stack/ReminderTemplateMainStack";

export interface DeleteReminderTemplateProps {}
export default function DeleteReminderTemplate(
  props: DeleteReminderTemplateProps
) {
  const {} = props;
  return (
    <>
      <ReminderTemplateMainStack
        additionalInputProps={{ isReadOnly: true }}
        additionalTextAreaProps={{ isReadOnly: true }}
      />
    </>
  );
}
