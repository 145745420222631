import ReminderTemplateMainStack from "./stack/ReminderTemplateMainStack";

export interface UpdateReminderTemplateProps {}
export default function UpdateReminderTemplate(
  props: UpdateReminderTemplateProps
) {
  const {} = props;
  return (
    <>
      <ReminderTemplateMainStack />
    </>
  );
}
