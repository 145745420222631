import {
  CreateVehicleCommand,
  SingleVehicleVm,
  UpdateVehicleVm,
  VehicleVm,
} from "api/Interface";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateVehicle from "components/Features/Vehicle/vehicle/CRUD/CreateVehicle";
import UpdateVehicle from "components/Features/Vehicle/vehicle/CRUD/UpdateVehicle";
import useAgent from "api/agent";
import AppContext from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import ViewVehicle from "components/Features/Vehicle/vehicle/CRUD/ViewVehicle";
import DeleteVehicle from "components/Features/Vehicle/vehicle/CRUD/DeleteVehicle";

export interface VehiclesProps {
  setVehicleId?: React.Dispatch<React.SetStateAction<VehicleVm>>;
}
export default function Vehicles(props: VehiclesProps) {
  const { setVehicleId } = props;
  const agent = useAgent().vehicleCalls;
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Name",
      name: "name",
      order: 1,
    },
    {
      displayName: "License Plate",
      name: "licensePlate",
      order: 2,
    },
    {
      displayName: "Description",
      name: "description",
      order: 3,
    },
  ];

  const { component } = useCrudTable<
    VehicleVm,
    CreateVehicleCommand,
    SingleVehicleVm,
    UpdateVehicleVm
  >({
    displayProps: {
      tableName: "Vehicles",
      caption: "Table showing all vehicles within the system",
      cardTitle: "Vehicle",
    },
    useTableProps: {
      Method: agent.Items,
    },
    crudComponents: {
      create: <CreateVehicle />,
      view: <ViewVehicle />,
      update: <UpdateVehicle />,
      delete: <DeleteVehicle />,
    },
    calls: agent,
    defaultValue: {
      chassisNumber: "",
      description: "",
      licensePlate: "",
      vehicleBluePrintId: "",
    },
    headers,
    onDoubleClick:
      setVehicleId == undefined ? () => {} : (data) => setVehicleId(data),
  });

  return <>{component()}</>;
}
