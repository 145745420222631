import VehicleRepairTemplateStack from "./stacks/VehicleRepairTemplateStack";

export interface DeleteVehicleRepairTemplateProps {}
export default function DeleteVehicleRepairTemplate(props: DeleteVehicleRepairTemplateProps) {
  const {} = props;
  return (
    <>
      <VehicleRepairTemplateStack
        additionalInputProps={{ isReadOnly: true }}
        additionalTextAreaProps={{ isReadOnly: true }}
      />
    </>
  );
}
