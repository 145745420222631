import { InputProps, TextareaProps } from "@chakra-ui/react";
import FormStack from "shared/components/form/organization/FormStack";
import VehicleRepairTemplateDescription from "../html/VehicleRepairTemplateDescription";
import VehicleRepairTemplateName from "../html/VehicleRepairTemplateName";

export interface VehicleRepairTemplateStackProps {
    additionalInputProps?: InputProps;
    additionalTextAreaProps?: TextareaProps;
  }
  export default function VehicleRepairTemplateStack(props: VehicleRepairTemplateStackProps) {
    const { additionalInputProps, additionalTextAreaProps } = props;
    return (
      <>
        <FormStack>
          <>
            <VehicleRepairTemplateName additionalInputProps={additionalInputProps} />
            <VehicleRepairTemplateDescription additionalTextareaProps={additionalTextAreaProps} />
          </>
        </FormStack>
      </>
    );
  }
  