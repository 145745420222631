import { useBreakpointValue } from "@chakra-ui/react";
import {
  BaseSearchParams,
  CallResponse,
  IAgentGenericCalls,
  IGetList,
} from "api/axiosInterface";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { PaginationInfo } from "shared/components/pagination/interfaces";
import { useToast } from "shared/components/toast/useToast";
import { Sleep } from "shared/functions/generic/asyncFunctions";

export interface UseTableProps<List> {
  Method: (
    params: URLSearchParams
  ) => Promise<AxiosResponse<IGetList<List>, any>>;
}

export default function useTable<List>(props: UseTableProps<List>) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { ErrorToast } = useToast();
  const { Method } = props;
  const [baseParams, setBaseParams] = useState<BaseSearchParams>({});
  const [triggerRefresh, setTriggerRefresh] = useState(Math.random());
  const [tableLoading, setTableLoading] = useState(false);
  const [itemsResponse, setItemsResponse] = useState<{
    items: List[];
    pagination: PaginationInfo;
  }>({
    items: [],
    pagination: {
      currentPage: 1,
      pageSize: 10,
      totalCount: 2,
      totalPages: 2,
    },
  });

  useEffect(() => {
    async function oo() {
      try {
        setTableLoading(true);
        await Sleep(200);

        const response = await Method(getSearchParams());
        console.log(response)
        setItemsResponse({
          items: response.data.items,
          pagination: response.data.metaData,
        });
        setTableLoading(false);
      } catch (e) {
        console.log(e)
        setTableLoading(false);
        ErrorToast({ description: "An error has occured" });
      }
    }
    oo();
  }, [baseParams, triggerRefresh]);

  const getSearchParams = () => {
    console.log(baseParams);
    const params = new URLSearchParams();
    Object.entries(baseParams).forEach((param) => {
      if (param[1] !== undefined) params.append(param[0], param[1]);
    });
    return params;
  };
  return {
    itemsResponse,
    setBaseParams,
    baseParams,
    tableLoading,
    triggerRefresh: () => {
      setTriggerRefresh(Math.random());
    },
    setTableLoading
  };
}
