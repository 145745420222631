import { ServerErrorResult } from "api/axiosInterface";
import { AxiosError } from "axios";
import { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Sleep } from "shared/functions/generic/asyncFunctions";
import GenericAlert from "shared/components/alert/GenericAlert";
import RegularButton from "shared/components/button/regular/RegularButton";
import { useToast } from "shared/components/toast/useToast";

export interface AdditionOps {
  loaderTitle: string;
  loaderDescription: string;
  successDescription: string;
  errorDescription: string;
  message?: string;
  title?: string;
  label: string;
  triggerReset?:boolean
  triggerMethod?: ()=>void
}
export interface AuxillaryCrudOptionProps {
  aria_label: string;
  tooltip: string;
  method: () => Promise<any>;
  icon: ReactElement;
  additionalOps: AdditionOps;
  
}
export default function AuxillaryCrudOption(props: AuxillaryCrudOptionProps) {
  const {
    aria_label,
    icon,
    method,
    additionalOps: {
      loaderDescription,
      loaderTitle,
      successDescription,
      errorDescription,
      message = "Are you sure?",
      title = "Alert Prompt",
      triggerReset = false,
      triggerMethod,
      label,
    },
    tooltip,
    ...buttonProps
  } = props;
  const {
    ProcessingToast,
    ErrorToast,
    SuccessToast,
    CustomProcessingToast,
    toast,
  } = useToast();
  const navigate = useNavigate();
  const [modalProps, setModalProps] = useState({
    show: false,
  });
  console.log("In");
  return (
    <>
      <RegularButton
        tooltip={tooltip}
        icon={icon}
        content=""
        buttonProps={{
          onClick: () => setModalProps({ show: true }),
          color: "accent",
          "aria-label": aria_label,
        }}
      />
      <GenericAlert
        title={title}
        message={message}
        reject={() => {
          setModalProps({ show: false });
        }}
        status={modalProps.show}
        submit={async () => {
          const processingId = await CustomProcessingToast(
            loaderTitle,
            loaderDescription
          );
          try {
            await Sleep(100);
            await Sleep(3000);
            await method();
            toast?.close(processingId);
            await SuccessToast({
              description: successDescription,
              title: "Success",
              position: "top",
              duration: 5000,
            });
            setModalProps({ show: false });
            if(triggerReset === true) triggerMethod!();
            //navigate("/")
          } catch (error: any) {
            toast?.close(processingId);
            const axiosError: AxiosError = error;
            const serverErrorResult: ServerErrorResult = (
              axiosError.response?.data as any
            ).result as ServerErrorResult;
            console.log(serverErrorResult);
            await ErrorToast({
              description: serverErrorResult as any,
              title: "Error",
              position: "top",
              duration: 5000,
            });
            setModalProps({ show: false });
          }          
        }}
      />
    </>
  );
}
