import { Box, Container, Stack, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { Route, Routes, Navigate } from "react-router-dom";
import HorizontalMenu from "components/Home/navigation/HorizontalMenu/HorizontalMenu";
import Authorize from "components/Authentication/Authorize";
import routes from "components/Home/routes";
import { useContext, useRef } from "react";
import AuthenticationContext from "contexts/AuthenticationContext";
import Login from "components/Authentication/Login";
import ForgetPassword from "components/Authentication/ForgetPassword";
import PhoneMenuLayout from "./navigation/PhoneMenu/PhoneMenuLayout";
import PhoneMenu from "./navigation/PhoneMenu/PhoneMenu";

export interface MainProps {}
export default function Main(props: MainProps) {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const {} = props;

  const { isAuthenticated, isUpdate } = useContext(AuthenticationContext);

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="*" element={<Authorize element={<></>} />} />
      </Routes>
    );
  }
  if (isUpdate) {
    return (
      <Routes>
        <Route path="*" element={<Authorize element={<></>} />} />
      </Routes>
    );
  }
  return (
    <>
      <Stack>
        {isDesktop ? (
          <HorizontalMenu />
        ) : (
          <PhoneMenu/>
        )}

        <Box
          bg="bg-canvas"
          borderTopLeftRadius={{ base: "none", lg: "2rem" }}
          height="full"
        >
          <Container py="8" height="full">
            <Routes>
              {routes.vehicleRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<Authorize element={route.component} />}
                  />
                );
              })}
            </Routes>
          </Container>
        </Box>
      </Stack>
    </>
  );
}
