import { InputProps } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericInput from "shared/components/form/input/GenericInput";

export interface VehicleLicensePlateProps {
  additionalInputProps?: InputProps;
}
export default function VehicleLicensePlate(props: VehicleLicensePlateProps) {
  const { additionalInputProps } = props;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return <>
  <GenericFormControl
        input={
          <GenericInput
          inputProps={{
            ...register("licensePlate", {
                required: `Vehicle License Plate is required`,
              }),
              
              ...additionalInputProps
          }}            
          />
        }
        label={`Vehicle License Plate`}
        placeHolder=""
        errorMessage={errors["licensePlate"]?.message as string | undefined}
        isRequired={true}
      />
  </>;
}
