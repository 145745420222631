import { Input, InputProps } from "@chakra-ui/react";
import { useFormContext, Controller, RegisterOptions, FieldValues } from "react-hook-form";
import GenericFormControl from "../form/GenericForm";

export interface GenericDateProps {
  additionalInputProps?: InputProps;
  title: string;
  property: string;
  rules?: RegisterOptions<FieldValues>;
}
export default function GenericDate(props: GenericDateProps) {
  const {
    register,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();
  const { additionalInputProps, property, title,rules } = props;
  return (
    <>
      <GenericFormControl
        input={
          <Controller
            rules={rules}
            control={control}
            name={property}
            render={({
              field: { onChange, name, value },
              fieldState: { isDirty, error },
              formState: { errors },
            }) => (
              <Input
                value={value || ""}
                onChange={(date) => {
                  onChange(date);
                }}
                type="date"
                id="large"
                size="lg"
                placeholder=" "
                data-peer
                {...additionalInputProps}
              />
            )}
          />
        }
        label={title}
        placeHolder=""
        errorMessage={errors[property]?.message as string | undefined}
        isRequired={true}
      />
    </>
  );
}
