import { Box, Button, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, HStack, useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";
import NavAccordion from "./NavAccordion";
import PhoneMenuLayout from "./PhoneMenuLayout";
import { items } from "../HorizontalMenu/data";

export interface PhoneMenuProps {}
export default function PhoneMenu(props: PhoneMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const {} = props;
  return (
    <>
      <Box >
        <Box as="nav" bg="bg-surface" boxShadow="sm">
          <PhoneMenuLayout onClickMenu={onOpen} isMenuOpen={isOpen} />
          <Drawer
            placement="left"
            initialFocusRef={menuButtonRef}
            isOpen={isOpen}
            onClose={onClose}
            size="full"
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader padding="0">
                <PhoneMenuLayout
                  onClickMenu={onClose}
                  isMenuOpen={isOpen}
                  menuButtonRef={menuButtonRef}
                />
              </DrawerHeader>
              <DrawerBody>
                <NavAccordion data={items} onClose = {onClose} />
              
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Box>
    </>
  );
}
