import { addServerErrors } from "api/genericCalls";
import {
  CallResponse,
  IAgentGenericCalls,
  ServerErrorResult,
} from "api/axiosInterface";
import { AxiosError, AxiosResponse } from "axios";
import { ReactElement, useContext, useEffect, useMemo, useReducer, useState } from "react";
import {
  FieldValues,
  SubmitErrorHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useToast } from "shared/components/toast/useToast";
import { Sleep } from "shared/functions/generic/asyncFunctions";
import {
  CreateMethod,
  CrudComponentsProps,
  CrudFuctionState,
  CrudFunctionAction,
  CrudTableDisplayProps,
  CrudTypes,
  ServerErrorProps,
} from "./interface";
import AppContext from "contexts/AppContext";

export interface UseCrudFunctionsProps<List, Create, Read, Update> {
  apiCalls: IAgentGenericCalls<List, Create, Read, Update>;
  displayProps: CrudTableDisplayProps;
  crudComponents: CrudComponentsProps;
  defaultValue: any;
  triggerRefresh: () => void;
}

export default function useCrudFunctions<List, Create, Read, Update>(
  props: UseCrudFunctionsProps<List, Create, Read, Update>
) {
  const [data, setData] = useState<any>();
  const [deleteState, setDeleteState] = useState(Math.random());
  const { ErrorToast, SuccessToast } = useToast();
  const onClose = () => {
    dispatch({ type: "closeModal", data: formHook });
  };
  const {
    apiCalls,
    displayProps,
    crudComponents,
    defaultValue,
    triggerRefresh,
  } = props;
  const intial: CrudFuctionState = {
    method: apiCalls.Create,
    entity: {},
    crudModalProps: {
      modalProps: {
        isLoading: true,
        modalBodyProps: <></>,
        modalFooterProps: <></>,
        modalHeaderProps: <>Loading</>,
        onClose,
        status: false,
      },
      actionProps: {
        cancelMethod: () => {},
        processMethod: () => {},
        type: CrudTypes.Create,
        isLoading: false,
      },
    },
  };

  const [{ crudModalProps, entity, method }, dispatch] = useReducer(
    reducer,
    intial
  );

  const{currentEntityId,setCurrentEntityId} = useContext(AppContext)

  const formHook = useForm({
    defaultValues: useMemo(() => {
      return entity;
    }, [entity]),
  });

  useEffect(() => {
    async function ff() {
      if (data !== undefined) {
        await onSubmit(data, method);
      }
    }
    ff();
  }, [data, deleteState]);

  const onSubmit = async (
    data: any,
    method: (data: any, id?:string) => Promise<AxiosResponse<CallResponse<any>, any>>
  ) => {
    console.log("Submit");
    try {
      await method(data, currentEntityId)
        .then(() => dispatch({ type: "resetForm", data: formHook }))
        .then(() =>
          SuccessToast({
            title: "Success",
            description: "Process was succesfully completed",
            duration: 5000,
            position: "top-right",
          })
        )
        .then(() => {
          switch (crudModalProps.actionProps.type) {
            case CrudTypes.Create: {
              break;
            }
            case CrudTypes.Update: {
              dispatch({ type: "closeModal", data: undefined });
              break;
            }
            case CrudTypes.Delete: {
              dispatch({ type: "closeModal", data: undefined });
              break;
            }
          }
        })
        .then(() => triggerRefresh());
    } catch (error: any) {
      
      const newError = error as AxiosError<any, any>;
      const response = newError.response as AxiosResponse<any,any>;
      console.log(response)
      switch (response.status) {
        case 400: {
          dispatch({
            type: "serverErrors",
            data: { errors: response.data, formHook },
          });
          ErrorToast({
            title: "Error",
            description: "Please correct errors on the form!!",
            duration: 5000,
          });
          break;
        }
        case 409: {
          ErrorToast({
            title: "Error",
            description: response.data,
            duration: 8000,
          });
          dispatch({ type: "closeModal", data: undefined });
          triggerRefresh();
          break;
        }
        default: {
          ErrorToast({
            title: "Error",
            description: "Process cannot be completed at this time.",
            duration: 5000,
          });
          break;
        }
      }
    }
  };
  const onError: SubmitErrorHandler<any> = () => {
    console.log("Error");
    ErrorToast({
      title: "Error",
      description: "Please correct the errors on the form pp.",
      duration: 5000,
    });
  };

  return {
    crudModalProps,
    entity,
    formHook,
    genericMethod: async (body: ReactElement) => {
      dispatch({ type: "setModalLoading", data: true });
      await Sleep(200).then((x) => {
        dispatch({
          type: "processMethod",
          data: {
            body: body,
            crudActionProps: {
              cancelMethod: () => {
                dispatch({ type: "resetForm", data: formHook });
              },
              processMethod: async () => {
                dispatch({ type: "setModalLoading", data: true });
                await Sleep(500).then(async (x) => {
                  await formHook
                    .handleSubmit(
                      async (data) => setData(data),
                      (error) => onError(error)
                    )()
                    .then((x) =>
                      dispatch({ type: "setModalLoading", data: false })
                    );
                });
              },
              type: CrudTypes.Create,
              isLoading: false,
            },
            entity: defaultValue,
            method: apiCalls.Create,
            modalHeaderProps: <>{`Create ${displayProps.cardTitle}`}</>,
            formHook,
          } as CreateMethod,
        });
      });

      dispatch({ type: "setModalLoading", data: false });
    },
    createMethod: async () => {
      dispatch({ type: "setModalLoading", data: true });
      await Sleep(200).then((x) => {
        dispatch({
          type: "processMethod",
          data: {
            body: crudComponents.create,
            crudActionProps: {
              cancelMethod: () => {
                dispatch({ type: "resetForm", data: formHook });
              },
              processMethod: async () => {
                dispatch({ type: "setModalLoading", data: true });
                await Sleep(500).then(async (x) => {
                  await formHook
                    .handleSubmit(
                      async (data) => setData(data),
                      (error) => onError(error)
                    )()
                    .then((x) =>
                      dispatch({ type: "setModalLoading", data: false })
                    );
                });
              },
              type: CrudTypes.Create,
              isLoading: false,
            },
            entity: defaultValue,
            method: apiCalls.Create,
            modalHeaderProps: <>{`Create ${displayProps.cardTitle}`}</>,
            formHook,
          } as CreateMethod,
        });
      });

      dispatch({ type: "setModalLoading", data: false });
    },
    viewMethod: async (id: string) => {
      dispatch({ type: "setModalLoading", data: true });
      await Sleep(200)
        .then(async (x) => {
          await apiCalls.Item(id).then((response) => {
            dispatch({
              type: "processMethod",
              data: {
                body: crudComponents.view,
                crudActionProps: {
                  cancelMethod: () => {
                    dispatch({ type: "resetForm", data: formHook });
                  },
                  processMethod: async () => {
                    dispatch({ type: "setModalLoading", data: true });
                    await Sleep(500).then((x) => {
                      formHook
                        .handleSubmit(
                          async (data) => await onSubmit(data, method),
                          (error) => onError(error)
                        )()
                        .then((x) =>
                          dispatch({ type: "setModalLoading", data: false })
                        );
                    });
                  },
                  type: CrudTypes.View,
                  isLoading: false,
                },
                entity: response.data,
                method: apiCalls.Item,
                modalHeaderProps: <>{`View ${displayProps.cardTitle}`}</>,
                formHook,
              } as CreateMethod,
            });
          });
        })
        .then((x) => dispatch({ type: "setModalLoading", data: false }));
    },
    updateMethod: async (id: string) => {
      dispatch({ type: "setModalLoading", data: true });
      await Sleep(200)
        .then(async (x) => {
          await apiCalls.Item(id).then((response) => {
            setCurrentEntityId(id);
            dispatch({
              type: "processMethod",
              data: {
                body: crudComponents.update,
                crudActionProps: {
                  cancelMethod: () => {
                    dispatch({ type: "resetForm", data: formHook });
                  },
                  processMethod: async () => {
                    dispatch({ type: "setModalLoading", data: true });
                    await Sleep(500).then((x) => {
                      formHook
                        .handleSubmit(
                          async (data) => setData(data),
                          (error) => onError(error)
                        )()
                        .then((x) =>
                          dispatch({ type: "setModalLoading", data: false })
                        );
                    });
                  },
                  type: CrudTypes.Update,
                  isLoading: false,
                },
                entity: response.data,
                method: apiCalls.Update,
                modalHeaderProps: <>{`Update ${displayProps.cardTitle}`}</>,
                formHook,
              } as CreateMethod,
            });
          });
        })
        .then((x) => dispatch({ type: "setModalLoading", data: false }));
    },
    deleteMethod: async (id: string) => {
      dispatch({ type: "setModalLoading", data: true });
      await Sleep(200)
        .then(async (x) => {
          await apiCalls.Item(id).then((response) => {
            dispatch({
              type: "processMethod",
              data: {
                body: crudComponents.delete,
                crudActionProps: {
                  cancelMethod: () => {
                    dispatch({ type: "resetForm", data: formHook });
                  },
                  processMethod: async () => {
                    dispatch({ type: "setModalLoading", data: true });
                    await Sleep(500).then((x) => {
                      formHook
                        .handleSubmit(()=>ii(id), (error) => onError(error))()
                        .then((x) =>
                          dispatch({ type: "setModalLoading", data: false })
                        );
                    });
                  },
                  type: CrudTypes.Delete,
                  isLoading: false,
                },
                entity: response.data,
                method: apiCalls.Delete,
                modalHeaderProps: <>{`Delete ${displayProps.cardTitle}`}</>,
                formHook,
              } as CreateMethod,
            });
          });
        })
        .then((x) => dispatch({ type: "setModalLoading", data: false }));
    },
  };
  function ii(id: string) {
    setData(id);
    setDeleteState(Math.random());
  }
}

function reducer(
  state: CrudFuctionState,
  action: CrudFunctionAction
): CrudFuctionState {
  switch (action.type) {
    case "processMethod": {
      const data = action.data as CreateMethod;
      let newState = { ...state };
      let modalProps = newState.crudModalProps.modalProps;
      modalProps.modalBodyProps = data.body;
      //modalProps.status = true;
      modalProps.modalHeaderProps = data.modalHeaderProps;

      newState.crudModalProps.actionProps = data.crudActionProps;
      newState.entity = data.entity;
      newState.method = data.method;
      data.formHook.reset(data.entity);
      return {
        ...newState,
      };
    }
    case "closeModal": {
      let newState = { ...state };
      let modalProps = newState.crudModalProps.modalProps;
      modalProps.status = false;
      modalProps.modalBodyProps = <></>;
      modalProps.modalHeaderProps = <>Loading</>;
      return {
        ...newState,
      };
    }
    case "resetForm": {
      const formHook = action.data as UseFormReturn<FieldValues, any>;
      formHook.reset(state.entity);
      return {
        ...state,
      };
    }
    case "setModalLoading": {
      const newState = { ...state };
      newState.crudModalProps.modalProps.isLoading = action.data as boolean;
      const decider = action.data as boolean;
      if (decider) {
        newState.crudModalProps.modalProps.status = true;
        return { ...newState };
      } else {
        return { ...newState };
      }
    }
    case "serverErrors": {
      console.log(action.data);
      const errors = action.data as ServerErrorProps;
      addServerErrors(errors.errors, errors.formHook);
      return { ...state };
    }
    case "submit": {
      const data = action.data;
      data();
      return { ...state };
    }
  }
}
