import { InputProps, TextareaProps } from "@chakra-ui/react";
import useAgent from "api/agent";
import { Props } from "chakra-react-select";
import FormStack from "shared/components/form/organization/FormStack";
import VehicleBluePrint from "../html/VehicleBluePrint";
import VehicleDescription from "../html/VehicleDescription";
import PlatesStack from "./PlatesStack";

export interface MainStackProps {
  additionalInputProps?: InputProps;
  additionalTextAreaProps?: TextareaProps;
  additionalSelectProps?: Props;
}
export default function MainStack(props: MainStackProps) {
  const {
    additionalInputProps,
    additionalSelectProps,
    additionalTextAreaProps,
  } = props;
  const agent = useAgent().vehicleBluePrintCalls;
  return (
    <>
      <FormStack>
        <>
          <VehicleBluePrint
            fetchDataCall={agent.Select!}
            isEnum={false}
            property="vehicleBluePrintId"
            title="Vehicle BluePrint"
            additionalProps={additionalSelectProps}
          />
          <VehicleDescription
            additionalTextareaProps={additionalTextAreaProps}
          />
          <PlatesStack additionalInputProps={additionalInputProps} />
        </>
      </FormStack>
    </>
  );
}
