import { requests, useGenericCalls } from "api/genericCalls";
import { CreateVehicleTypeCommand, SingleVehicleTypeVm, UpdateVehicleTypeVm, VehicleTypeVm } from "api/Interface";


export default function useVehicleTypeCalls(){

    const url = process.env.REACT_APP_API_VEHICLETYPE;
    
    const vehicleTypeCalls = useGenericCalls<
    VehicleTypeVm,
    CreateVehicleTypeCommand,
    SingleVehicleTypeVm,
    UpdateVehicleTypeVm
    >(url!);
    vehicleTypeCalls.Select = () => {
        return requests.get(`${url}/Select`, new URLSearchParams());
    }
    return vehicleTypeCalls
}
