import { Box, Stack, Text, useBreakpointValue } from "@chakra-ui/react";
import { AdditionalOption, CrudOptions } from "hooks/crud/interface";
import { PaginationInfo } from "shared/components/pagination/interfaces";
import Pagination from "shared/components/pagination/Pagination";
import TableSearch from "shared/components/search/TableSearch";
import GenericTable, { TableProps } from "../table/GenericTable";

export interface CrudTableProps {
  tableProps: TableProps;
  paginationProps: PaginationInfo;
  crudOptions?: CrudOptions;
  additionalOptions?: AdditionalOption[];
}
export default function CrudTable(props: CrudTableProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { tableProps, paginationProps, crudOptions, additionalOptions } = props;
  const {
    tableHeaderProps: { baseParam, setBaseParam },
  } = tableProps;
  return (
    <>
      <Stack spacing={5}
      >
        <Box px={{ base: "4", md: "6" }} pt="5">
          <Stack
            direction={{ base: "column", md: "row" }}
            justify="space-between"
          >
            <Text  color="accent" fontSize="2xl" fontWeight="medium">
              Table
            </Text>
            <TableSearch baseParam={baseParam} setBaseParam={setBaseParam} />
          </Stack>
        </Box>
        <Box overflowX={"auto"}>
          <GenericTable
            {...tableProps}
            crudOptions={crudOptions}
            additionalOptions={additionalOptions}
          />
        </Box>

        <Pagination
          paginationProps={paginationProps}
          baseParam={tableProps.tableHeaderProps.baseParam}
          setBaseParam={tableProps.tableHeaderProps.setBaseParam}
        />
      </Stack>
    </>
  );
}
