import VehicleBluePrintStack from "./stack/VehicleBluePrintStack";

export interface DeleteVehicleBluePrintProps {}
export default function DeleteVehicleBluePrint(
  props: DeleteVehicleBluePrintProps
) {
  const {} = props;
  return (
    <>
      <VehicleBluePrintStack additionalInputProps={{ isReadOnly: true }} additionalProps={{isReadOnly:true}}/>
    </>
  );
}
