import { InputProps } from "@chakra-ui/react";
import { ReminderTemplateVm, VehicleVm } from "api/Interface";
import { useState, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import FormStack from "shared/components/form/organization/FormStack";
import ReminderDate from "../html/ReminderDate";
import ReminderTemplateIdForReminder from "../html/ReminderTemplateIdForReminder";
import ReminderVehicleId from "../html/ReminderVehicleId";

export interface ReminderMainStackProps {
  additionaInputProps?: InputProps;
  isDisabled?: boolean;
}
export default function ReminderMainStack(props: ReminderMainStackProps) {
  const { additionaInputProps, isDisabled = false } = props;
  const [vehicle, setVehicle] = useState<VehicleVm>({});
  const [reminderTemplate, setReminderTemplate] = useState<ReminderTemplateVm>(
    {}
  );
  const count = useRef(0);
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useFormContext();
  useEffect(() => {
    const vehicleId: string = getValues("vehicleId");
    if (vehicleId !== "" || (vehicleId !== undefined && count.current < 1)) {
      setVehicle({
        vehicleId,
        name: getValues("vehicleName"),
        description: "",
        licensePlate: "",
      });
      setReminderTemplate({
        reminderTemplateId: getValues("reminderTemplateId"),
        name: getValues("templateName"),
        description: "",
      });
    }
  }, []);
  useEffect(() => {
    setValue("vehicleId", vehicle.vehicleId);
    if (count.current > 2) {
      trigger("vehicleId");
    }
    count.current++;
  }, [vehicle]);
  useEffect(() => {
    setValue("reminderTemplateId", reminderTemplate.reminderTemplateId!);
    if (count.current > 2) {
      trigger("reminderTemplateId");
    }
    count.current++;
  }, [reminderTemplate]);

  return (
    <>
      <FormStack>
        <>
          <ReminderVehicleId
            vehicle={vehicle}
            setVehicle={setVehicle}
            isDisabled={isDisabled}
          />
          <ReminderTemplateIdForReminder
            isDisabled={isDisabled}
            vehicleId={vehicle.vehicleId}
            reminderTemplate={reminderTemplate}
            setReminderTemplate={setReminderTemplate}
          />
          <ReminderDate additionDateProps={additionaInputProps} />
        </>
      </FormStack>
    </>
  );
}
