import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

export interface useSelectProps {
  formHook: UseFormReturn<any, any>;
  placeHolder?: string;
  name: string;
  defOptions?: { value: string; label: string }[];
  fetchDataCall: (
    params: URLSearchParams
  ) => Promise<AxiosResponse<any, any>>;
  isEnum?: boolean;
}

export default function useSelect(props: useSelectProps) {
  const {
    fetchDataCall,
    formHook,
    name,
    defOptions = [],
    isEnum,
    placeHolder = "Select from Options Below",
  } = props;
  const [title, setTitle] = useState<{ value?: string; label: string }>({
    value: undefined,
    label: placeHolder,
  });
  const [options, setOptions] =
    useState<{ value: string; label: string }[]>(defOptions);
  useEffect(() => {
    fetchDataCall(new URLSearchParams()).then((x) => setOptions(x.data));
  }, []);
  useEffect(() => {
    if (options.length === 0) return;
    console.log(options)
    console.log(formHook.getValues())
    const val = formHook.getValues()[name];
    if (val !== 0 && val !== "" && val!== undefined) {
      console.log(val)
      const selectedVal = options.find(
        (x) => x.value?.toString() === val.toString()
      )!;
      if (isEnum) selectedVal.value = selectedVal.value.toString();
      setTitle({ ...selectedVal });
    }
  }, [options]);
  useEffect(() => {
    const val = formHook.getValues()[name];
    if (val === "0") {
      formHook.setValue(name, "");
    } else {
      formHook.setValue(name, val);
    }
  }, []);
  useEffect(() => {
    if (title.value === undefined) return;
    else {
      formHook.setValue(name, title.value!.toString());
    }
  }, [title]);
  
 
  return {
    title,
    setTitle,
    options,
  };
}
