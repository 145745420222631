import {
  CreateVehicleTypeCommand,
  SingleVehicleTypeVm,
  UpdateVehicleTypeVm,
  VehicleTypeVm,
} from "api/Interface";
import useCrudTable from "hooks/crud/useCrudTable";
import { TableColumnHeaderProps } from "shared/components/table/interfaces";
import CreateVehicleType from "components/Features/Vehicle/vehicle-type/CRUD/CreateVehicleType";
import UpdateVehicleType from "components/Features/Vehicle/vehicle-type/CRUD/UpdateVehicleType";
import useAgent from "api/agent";
import AppContext from "contexts/AppContext";
import { useContext, useEffect, useState } from "react";
import ViewVehicleType from "components/Features/Vehicle/vehicle-type/CRUD/ViewVehicleType";
import DeleteVehicleType from "components/Features/Vehicle/vehicle-type/CRUD/DeleteVehicleType";

export interface VehicleTypesProps {}
export default function VehicleTypes(props: VehicleTypesProps) {
 
  const agent = useAgent().vehicleTypeCalls;
  const headers: TableColumnHeaderProps[] = [
    {
      displayName: "Name",
      name: "name",
      order: 1,
    },
   
  ];

  const { component } = useCrudTable<
    VehicleTypeVm,
    CreateVehicleTypeCommand,
    SingleVehicleTypeVm,
    UpdateVehicleTypeVm
  >({
    displayProps: {
      tableName: "Vehicle Types",
      caption: "Table showing all vehicle types within the system",
      cardTitle: "Vehicle Types",
    },
    useTableProps: {
      Method: agent.Items,
    },
    crudComponents: {
      create: <CreateVehicleType />,
      view:<ViewVehicleType/>,
      update: <UpdateVehicleType />,
      delete: <DeleteVehicleType/>,
    },
    
    calls: agent,
    defaultValue: {name:"",description:""},
    headers,
  });

  const {} = props;
  return <>{component()}</>;
}
