import axios, { AxiosResponse } from "axios";
import { UseFormReturn } from "react-hook-form";
import { CallResponse, IAgentGenericCalls, IGetList, ServerError, ServerErrorResult } from "api/axiosInterface";
import { useContext, useEffect } from "react";
import AppContext from "contexts/AppContext";

const requestBody = (response: AxiosResponse<any, any>) =>
  response;
export const requests = {
  get: (url: string, params?: URLSearchParams) => {
    return axios.get(url, { params }).then(requestBody);
  },
  post: (url: string, body: {}) => {
    return axios.post(url, body).then(requestBody);
  },
  put: (url: string, body: {}) => {
    return axios.put(url, body).then(requestBody);
  },
  delete: (url: string) => {
    return axios.delete(url).then(requestBody);
  },
};

const ff = {
  requests,
};

export function useGenericCalls<List, Create, Read, Update>(call: string) {
  
  //const {currentEntityId} = useContext(AppContext)
  const Calls: IAgentGenericCalls<List, Create, Read, Update> = {
    Create: function (
      data: Create
    ): Promise<AxiosResponse<any, any>> {
      console.log(data)
      return requests.post(`${call}`, data as any);
    },
    Update: function (
      data: Update, currentEntityId: string
    ): Promise<AxiosResponse<any, any>> {
      console.log(currentEntityId)
      return requests.put(`${call}/${currentEntityId}`, data as any);
    },
    Items: function (
      params: URLSearchParams
    ): Promise<AxiosResponse<IGetList<List>, any>> {
      return requests.get(`${call}`, params);
    },
    Item: function (
      id: string
    ): Promise<AxiosResponse<Read, any>> {
      return requests.get(`${call}/${id}`);
    },
    Delete: function (
      id: string
    ): Promise<AxiosResponse<any, any>> {
      return requests.delete(`${call}/${id}`);
    },
  };
  return Calls;
}

export default ff;
export function addServerErrors(
  serverErrors: ServerError[],
  formHook: any
) {
  serverErrors.forEach((error) => {
    console.log(error)
    formHook.setError(
      error.PropertyName.charAt(0).toLowerCase() + error.PropertyName.slice(1),
      {
        type: "server",
        message: error.ErrorMessage,
      }
    );
    console.log(formHook.formState.errors);
  });
}
