import { useColorMode } from "@chakra-ui/react";
import { FiMoon, FiSun } from "react-icons/fi";
import RegularButton from "shared/components/button/regular/RegularButton";

export interface ColorSwitcherProps {}
export default function ColorSwitcher(props: ColorSwitcherProps) {
  const {} = props;
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <>
      <RegularButton
        buttonProps={{
          "aria-label": "color-mode",
          onClick: toggleColorMode,
        }}
        content=""
        icon={isDark ? <FiSun /> : <FiMoon />}
      />
    </>
  );
}
