import { Fade, Progress } from "@chakra-ui/react";

export interface ModalLoaderProps {
  isLoading: boolean;
}
export default function ModalLoader(props: ModalLoaderProps) {
  const { isLoading } = props;
  return (
    <>
      <Fade in={isLoading}>
        <Progress isIndeterminate />
      </Fade>
    </>
  );
}
