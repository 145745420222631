import { InputProps } from "@chakra-ui/react";
import { FieldValues, RegisterOptions } from "react-hook-form";
import GenericDate, {
  GenericDateProps,
} from "shared/components/date/GenericDate";

export interface FormHookDateInputProps {
  additionalDateProps?: InputProps;
  property: string;
  label: string;
  rules?: RegisterOptions<FieldValues>;
}
export default function FormHookDateInput(props: FormHookDateInputProps) {
  const { label, property, additionalDateProps, rules } = props;
  return (
    <>
      <GenericDate
        property={property}
        title={label}
        additionalInputProps={additionalDateProps}
        rules={rules}
      />
    </>
  );
}
