import { InputProps, Text, useDisclosure } from "@chakra-ui/react";
import { VehicleVm } from "api/Interface";
import Vehicles from "components/Features/Vehicle/vehicle/Vehicles";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FiSearch } from "react-icons/fi";
import PrimaryButton from "shared/components/button/regular/PrimaryButton";
import GenericDrawer from "shared/components/drawer/GenericDrawer";
import GenericFormControl from "shared/components/form/GenericForm";
import GenericInput from "shared/components/form/input/GenericInput";
import FormStack from "shared/components/form/organization/FormStack";

export interface ReminderVehicleIdProps {
  isDisabled?: boolean;
  setVehicle: React.Dispatch<React.SetStateAction<VehicleVm>>;
  vehicle: VehicleVm;
}
export default function ReminderVehicleId(props: ReminderVehicleIdProps) {
  const { setVehicle, vehicle, isDisabled = true } = props;
  useEffect(() => {
    onClose();
  }, [vehicle]);
  const {
    register,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register("vehicleId", {
      required: "Please provide the Vehicle this reminder is for",
    });
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <GenericFormControl
        labelVariant="regular"
        errorMessage={errors.vehicleId?.message as string | undefined}
        label={
          <>
            <FormStack type="horizontal" spacing={25}>
              <>
                <Text>Vehicle</Text>
                <PrimaryButton
                  buttonProps={{
                    mb: 5,
                    "aria-label": "Vehicle Search",
                    onClick: () => onOpen(),
                    isDisabled: isDisabled,
                  }}
                  icon={<FiSearch />}
                  content=""
                />
              </>
            </FormStack>
          </>
        }
        placeHolder=""
        input={
          <>
            <GenericInput
              inputProps={{
                value: vehicle.name,
                isReadOnly: true,
              }}
            />
          </>
        }
      />
      <GenericDrawer
        content={<Vehicles setVehicleId={setVehicle} />}
        header="Vehicles"
        isOpen={isOpen}
        onClose={onClose}
        placement={"top"}
        size="full"
      />
    </>
  );
}
