import { InputProps } from "@chakra-ui/react";
import { ReminderVm } from "api/Interface";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import FormStack from "shared/components/form/organization/FormStack";
import ExpenditureCost from "../html/ExpenditureCost";
import ExpenditureDatePaid from "../html/ExpenditureDatePaid";
import RepairExpenditureTemplate from "../html/RepairExpenditureTemplate";
import { Props } from "chakra-react-select";
import RepairExpenditureVehicle from "../html/RepairExpenditureVehicle";


export interface VehicleExpenditureMainStackProps {
  additionaInputProps?: InputProps;
  isDisabled?: boolean;
  additionalSelectProps?: Props;
}
export default function VehicleExpenditureMainStack(
  props: VehicleExpenditureMainStackProps
) {
  const { additionaInputProps, additionalSelectProps, isDisabled = false } = props;
  const [reminder, setReminder] = useState<ReminderVm>({});
  const count = useRef(0);
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    getValues,
  } = useFormContext();
  useEffect(() => {
    const reminderId: string = getValues("reminderId");
    if (reminderId !== "" || (reminderId !== undefined && count.current < 1)) {
      setReminder({
        reminderId,
        name: getValues("reminderName"),
        vehicle: getValues("vehicle"),
        dateDue: getValues("dateDue"),
      });
    }
  }, []);
  useEffect(() => {
    setValue("reminderId", reminder.reminderId);
    if (count.current > 1) {
      trigger("reminderId");
    }
    count.current++;
  }, [reminder]);
  return (
    <>
      <FormStack spacing={10}>
        <>
          <RepairExpenditureTemplate
            isEnum = {false}
            title="Repair Template"
            property="vehicleRepairTemplateId"
            additionalProps={additionalSelectProps}
          />
          <RepairExpenditureVehicle
            isEnum = {false}
            title="Vehicle"
            property="vehicleId"
            additionalProps={additionalSelectProps}
          />

          <ExpenditureDatePaid additionDateProps={additionaInputProps} />
          <ExpenditureCost />
        </>
      </FormStack>
    </>
  );
}
